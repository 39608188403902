/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect } from 'react';
import {
    Button,
    Divider,
    FlexCol,
    FlexContainer,
    FlexRow,
    Checkbox,
    Notification,
    TextField,
    Select,
    SelectOption,
    SpinnerModal,
} from 'sarsaparilla';
import { map, cloneDeep, set, get } from 'lodash';
import { AnyAction, bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { IAccount } from '../../shared/types/auth/account';
import { IApiKey } from '../../shared/types/auth/apiKey';
import { IOrganization } from '../../shared/types/asset/organization';
import { Globals } from '../../shared/globals';
import AnyFunction = Globals.AnyFunction;
import mergeDefaults = Globals.mergeDefaults;
import TargetedEvent = Globals.TargetedEvent;
import { accountDefaults, apiKeyDefaults } from '../../shared/constants/auth';
import '../../scss/containers/UserAdminPage/_UserAdminPage.scss';
import { getUserSearchTerm } from '../../reducers/userSearch';
import { fetchAccount } from '../../actions/account';

const defaultProps = {
    isLoading: false,
    fetchIfDifferent: false,
};

type CheckedEvent = TargetedEvent<{ checked: boolean }>;

type UserAdminUserDisplayProps = {
    account: {
        account: IAccount;
        apiKey: Partial<IApiKey>;
    };
    orgOptions: IOrganization[];
    onEnable: AnyFunction;
    onChange: AnyFunction;
    isDisabled: boolean;
    isLoading: boolean;
    term?: string;
    fetchIfDifferent?: boolean;
};

export function UserAdminUserDisplay(props: UserAdminUserDisplayProps) {
    const dispatch = useDispatch();
    const {
        isDisabled,
        isLoading,
        account,
        orgOptions,
        onEnable,
        onChange,
        term,
        fetchIfDifferent,
    } = mergeDefaults(props, defaultProps);
    const {
        account: { firstName, lastName, email, accountId } = accountDefaults,
        apiKey,
    } = account;

    const hasResult =
        (!!accountId && !fetchIfDifferent) || (fetchIfDifferent && term !== '');
    const isDeveloper = apiKey && apiKey.externalId && apiKey.apiKey !== '';
    const [pendingApiKey, updateApiKey] = useState(apiKey);
    const { writeAccess, locked, orgId, adminAccess } = mergeDefaults(
        pendingApiKey,
        apiKeyDefaults
    );
    const [isUpdateAccountSuccess, updateAccountSuccess] = useState(false);
    const [isEnableDeveloperAccessSuccessful, updateDeveloperAccessSuccess] =
        useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        updateApiKey(apiKey);
    }, [account]);

    const handle = (
        updateDeveloperAccess: boolean,
        updateAccount: boolean,
        err: any = null
    ) => {
        updateDeveloperAccessSuccess(updateDeveloperAccess);
        updateAccountSuccess(updateAccount);
        if (err) {
            setError(err);
        }
        if (fetchIfDifferent && term) {
            dispatch(fetchAccount({ query: term }) as unknown as AnyAction);
        }
    };

    const enableDeveloperAccess = () => {
        onEnable()
            .then(() => handle(true, false))
            .catch((responseError) => handle(false, false, responseError));
    };

    const updateInput = (key, value) => {
        const newPendingApiKey = cloneDeep(pendingApiKey);
        set(newPendingApiKey, key, value);
        updateApiKey(newPendingApiKey);
    };

    const handleSubmit = () => {
        onChange(pendingApiKey)
            .then(() => handle(false, true))
            .catch((responseError) => handle(false, false, responseError));
    };

    return (
        <FlexContainer>
            <SpinnerModal isOpen={isLoading} isSpinning={isLoading} />

            {isEnableDeveloperAccessSuccessful && (
                <Notification
                    type="success"
                    className="mb-2"
                    dismissNotification={() => updateDeveloperAccessSuccess(false)}
                    shouldFocusOnMount
                >
                    Enable Developer Access Successful
                </Notification>
            )}

            {isUpdateAccountSuccess && (
                <Notification
                    type="success"
                    className="mb-2"
                    dismissNotification={() => updateAccountSuccess(false)}
                    shouldFocusOnMount
                >
                    Update Account Successful
                </Notification>
            )}

            {error && (
                <Notification type="error" className="mb-2" shouldFocusOnMount>
                    {error}
                </Notification>
            )}

            <FlexRow>
                <FlexCol>
                    {!hasResult && <p>No results</p>}

                    {hasResult && isDeveloper && (
                        <div>
                            <FlexRow className="mb-2">
                                <FlexCol>
                                    <TextField
                                        id="user-first-name"
                                        label="First Name"
                                        value={firstName}
                                        isDisabled
                                    />
                                </FlexCol>
                                <FlexCol>
                                    <TextField
                                        id="user-last-name"
                                        label="Last Name"
                                        value={lastName}
                                        isDisabled
                                    />
                                </FlexCol>
                            </FlexRow>

                            <FlexRow className="mb-2">
                                <FlexCol>
                                    <TextField
                                        id="user-email"
                                        label="Email"
                                        value={email}
                                        isDisabled
                                    />
                                </FlexCol>
                                <FlexCol>
                                    <TextField
                                        id="user-api-key"
                                        label="API key"
                                        value={get(account, 'apiKey.apiKey')}
                                        isDisabled
                                    />
                                </FlexCol>
                            </FlexRow>

                            <Divider />

                            <Checkbox
                                id="user-locked"
                                className="danger mb-1"
                                label="Disable API Access"
                                value="controlled"
                                isChecked={locked}
                                isDisabled={isDisabled}
                                onChange={(e: CheckedEvent) =>
                                    updateInput('locked', e.target.checked)
                                }
                            />

                            <Checkbox
                                id="user-data-steward-access"
                                className="mb-1"
                                label="Data Steward Access"
                                value="controlled"
                                isChecked={writeAccess}
                                isDisabled={isDisabled}
                                onChange={(e: CheckedEvent) =>
                                    updateInput('writeAccess', e.target.checked)
                                }
                            />

                            <Select
                                id="user-search-org"
                                className="mb-1"
                                label="Organization"
                                value={orgId}
                                isDisabled={isDisabled || !writeAccess}
                                onChange={(e: TargetedEvent) =>
                                    updateInput('orgId', e.target.value)
                                }
                            >
                                <SelectOption value="" isDisabled>
                                    All Organizations
                                </SelectOption>

                                {map(orgOptions, ({ label, value }) => {
                                    return (
                                        <SelectOption key={value} value={value}>
                                            {label}
                                        </SelectOption>
                                    );
                                })}
                            </Select>

                            <Checkbox
                                id="user-admin-access"
                                label="Admin Access"
                                value="controlled"
                                isChecked={!!adminAccess}
                                isDisabled={isDisabled}
                                onChange={(e: CheckedEvent) =>
                                    updateInput('adminAccess', e.target.checked)
                                }
                            />

                            <Divider />

                            <Button onClick={handleSubmit}>Save Changes</Button>
                        </div>
                    )}

                    {hasResult && !isDeveloper && (
                        <div>
                            <Notification
                                type="info"
                                title="Developer Access not enabled"
                            >
                                User has not enabled developer access for this account
                            </Notification>

                            <Button
                                appearance="secondary"
                                className="enable-developer-access-btn"
                                onClick={enableDeveloperAccess}
                            >
                                Enable Developer Access For This User
                            </Button>
                        </div>
                    )}
                </FlexCol>
            </FlexRow>
        </FlexContainer>
    );
}

const mapStateToProps = (state) => ({
    term: getUserSearchTerm(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAccount,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserAdminUserDisplay);
