/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    SAVE_ADD_ERROR,
    SAVE_CLEAR_ERRORS,
    SAVE_PROGRESS_CLEAR,
    SAVE_PROGRESS_START,
    SAVE_PROGRESS_STEP,
} from '../shared/constants/actions';

export const saveStatusStart = (count) => (dispatch) =>
    dispatch({ type: SAVE_PROGRESS_START, payload: count });
export const saveStatusStep = () => (dispatch) => dispatch({ type: SAVE_PROGRESS_STEP });
export const saveStatusClear = () => (dispatch) =>
    dispatch({ type: SAVE_PROGRESS_CLEAR });

export const saveAddError = (error) => (dispatch) =>
    dispatch({ type: SAVE_ADD_ERROR, payload: error });
export const saveClearErrors = () => (dispatch) => dispatch({ type: SAVE_CLEAR_ERRORS });
