/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { uniqueId } from 'lodash';
import { IItemKeyConfig, ItemTranslator } from './itemTranslator';
import { Globals } from '../../globals';
import {
    recAreaDecodeActivityKeys,
    recAreaDecodeAddressKeys,
    recAreaDecodeKeys,
    recAreaEncodeActivityKeys,
    recAreaEncodeAddressKeys,
    recAreaEncodeKeys,
} from '../../constants/asset/recArea';
import { RecAreaDecodeFacility } from '../../types/asset/facility';
import { IRecAreaDecodeRecord } from '../../types/asset/recArea';
import { IAssetDecodeRecord } from '../../types/asset/asset';
import AnyObject = Globals.AnyObject;

const recAreaItemConfig: IItemKeyConfig = {
    activity: {
        encode: recAreaEncodeActivityKeys,
        decode: recAreaDecodeActivityKeys,
    },
    address: {
        encode: recAreaEncodeAddressKeys,
        decode: recAreaDecodeAddressKeys,
    },
    main: {
        encode: recAreaEncodeKeys,
        decode: recAreaDecodeKeys,
    },
};

class RecArea extends ItemTranslator {
    decode = (record: IAssetDecodeRecord) => {
        const { decode: decodeKeys } = this.keys.main;
        const asset = this.translate(decodeKeys, record, { _key: uniqueId('asset-') });
        const { RECAREAADDRESS, FACILITY, ACTIVITY } = record as IRecAreaDecodeRecord;
        return {
            ...asset,
            facilities: this.decodeConverse(FACILITY),
            addresses: this.decodeAddresses(RECAREAADDRESS),
            activities: this.decodeActivities(ACTIVITY),
        };
    };

    encode = <Result = AnyObject>(record: AnyObject) => {
        const { encode: encodeKeys } = this.keys.main;
        return this.translate<Result>(encodeKeys, record);
    };

    // RecArea.decodeFacilites
    decodeConverse = (records: RecAreaDecodeFacility[]) => {
        return records.map((record) => ({
            _key: uniqueId('facility-'),
            id: record.FacilityID,
            name: record.FacilityName,
            link: record.ResourceLink,
        }));
    };
}

export default new RecArea(recAreaItemConfig);
