/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import '../src/polyfills';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom-v5-compat';

import { LaunchDarklyProvider } from 'sarsaparilla';
import { RestoreScroll } from 'shared-ui/components/RestoreScroll/RestoreScroll';

import { store } from '../src/store';
import App from '../src/App';
import './index.scss';

const rootElement = document.getElementById('recApp');
if (!rootElement) throw new Error('Failed to find the recApp root element');

const root = createRoot(rootElement);
root.render(
    <div id="developer-portal" className="dev-portal-content">
        <Provider store={store}>
            <BrowserRouter>
                <RestoreScroll />
                <LaunchDarklyProvider>
                    <App />
                </LaunchDarklyProvider>
            </BrowserRouter>
        </Provider>
    </div>
);
