/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Globals } from '../globals';
import { StewardAssetInvalidItem } from '../types/asset/asset';
import generateUuid = Globals.generateUuid;
import AnyFunction = Globals.AnyFunction;

export type ValidationType =
    | 'recArea'
    | 'facility'
    | 'address'
    | 'link'
    | 'media'
    | 'event'
    | 'activity';

const requiredField = (value: any) => (!value ? ['Required Field'] : null);

const validationSpecificFields: { [key in ValidationType]: string[] } = {
    recArea: ['name', 'description', 'directions'],
    facility: ['name', 'description', 'directions'],
    address: ['stateCode', 'countryCode', 'address1'],
    link: ['url', 'title', 'description'],
    media: ['title'],
    event: ['name'],
    activity: [],
};

const validationFields: { [key in ValidationType]: string[] } = {
    recArea: ['recarea', 'recareas'],
    facility: ['facility', 'facilities'],
    activity: [
        'recareaactivity',
        'recareaactivities',
        'facilityactivity',
        'facilityactivities',
    ],
    address: [
        'recareaaddress',
        'recareaaddresses',
        'facilityaddress',
        'facilityaddresses',
    ],
    link: ['recarealink', 'recarealinks', 'facilitylink', 'facilitylinks'],
    media: ['recareamedia', 'facilitymedia'],
    event: ['recareaevent', 'recareaevents', 'facilityevent', 'facilityevents'],
};

export function validate(typeName: string, field: string, value: any) {
    let type = void 0;
    for (const key in validationFields) {
        const fields: string[] = validationFields[key];
        if (fields.includes(typeName)) {
            type = key;
            break;
        }
    }
    return type ? validateSpecific(field, value, type) : type;
}

export function validateMediaImage(media: Partial<{ url: string; image: string }>) {
    const { url, image } = media;
    if (!url && !image) {
        const fromField = (field: string) => ({
            field,
            messages: ['Must have either an image file or a URL'],
        });
        return [fromField('url'), fromField('image')];
    }
    return null;
}

export function createInvalidItem(field: string, errorText: string, invalid = true) {
    return {
        id: generateUuid(),
        field,
        errorText,
        invalid,
    };
}

export function determineNewInvalidItems(
    validatorObject: Record<string, AnyFunction>,
    field: string,
    value: any,
    invalidItems: StewardAssetInvalidItem[],
    prefix: string
) {
    const validatorFn = validatorObject[field];
    if (validatorFn) {
        const isInvalid = !validatorFn(value);
        let item;
        // already in our list, need to update it
        const existingItem = invalidItems.filter((i) => i.field === field);
        if (existingItem && existingItem.length === 1) {
            item = { ...existingItem[0] };
            item.invalid = isInvalid;
        } else {
            item = createInvalidItem(field, `${prefix} ${field} is invalid`, isInvalid);
        }
        return invalidItems.filter((f) => f.field !== field).concat([item]);
    }

    return void 0;
}

function validateSpecific(field: string, value: any, type: ValidationType) {
    const fields = validationSpecificFields[type];
    if (fields.length === 0) return null;
    return fields.some((f) => f === field) ? requiredField(value) : null;
}
