/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducer';

type WindowWithReduxDevTools = Window & { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any };

const initialState = {
    portal: {},
};
const composeEnhancer =
    (window as WindowWithReduxDevTools).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer,
    initialState,
    composeEnhancer(applyMiddleware(thunk))
);
