/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as CryptoJS from 'crypto-js';

export const encrypt = (
    key = process.env.ENCRYPTION_KEY,
    value: any = 'undefined',
    keySize?: number
): string => {
    const parsedKey = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(value.toString()),
        parsedKey,
        {
            keySize: keySize || 128 / 8,
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }
    );
    return encrypted.toString();
};

export const decrypt = (
    key = process.env.ENCRYPTION_KEY,
    value: any = 'undefined',
    keySize?: number
): string => {
    const parsedKey = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Utf8.parse(key);
    const decrypted = CryptoJS.AES.decrypt(value, parsedKey, {
        keySize: keySize || 128 / 8,
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
};
