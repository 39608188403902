/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useNavigate } from 'react-router-dom-v5-compat';

import { Button, Icon, TYPES } from 'sarsaparilla';

import { Action } from 'ui-internal-permit-itinerary/src/actions';
import { ICurrentUser } from '../shared/types/auth/currentUser';
import { Globals } from '../shared/globals';
import Auth, { AuthTargetedTypes } from '../shared/services/auth';
import { getCurrentUser } from '../reducers/currentUser';
import { getMachineKeys } from '../reducers/apiKey';
import { logout as logoutAction, reload as reloadAction } from '../actions/currentUser';
import { formatFullNameFromToken } from '../shared/utils/string';
import HeaderLink from '../shared/components/HeaderLink';
import { apiKeyDefaults } from '../shared/constants/auth';
import AnyFunction = Globals.AnyFunction;
import TargetedEvent = Globals.TargetedEvent;
import RidbLink from '../shared/components/RidbLink';
import { updateUserSearchTerm as updateUserSearchTermAction } from '../actions/userSearch';
import { fetchMachineKeys as fetchMachineKeysAction } from '../actions/apiKeys';
import { IMachineApiKey } from '../shared/types/auth/machineApiKey';
import '../scss/containers/_Header.scss';
import '../scss/containers/_AccountInfo.scss';

type AccountInfoState = {
    currentUser?: ICurrentUser;
    apiKeys: IMachineApiKey[];
};

type AccountInfoProps = AccountInfoState & {
    logout: AnyFunction;
    reload: AnyFunction;
    updateUserSearchTerm: AnyFunction;
    fetchMachineKeys: AnyFunction;
};

type PopoverStateValues = 'open' | 'close';

function AccountInfo(props: AccountInfoProps) {
    const navigate = useNavigate();
    const anchorRef = useRef(null);

    const [showingPopover, setShowingPopover] = useState(false);

    const handleLogout = () => {
        const { logout, updateUserSearchTerm } = props;
        logout().then(() => {
            Auth.clearData();
            navigate('/');
            updateUserSearchTerm('');
        });
    };

    const togglePopover = (popover: PopoverStateValues = void 0) => {
        let setTo: boolean = void 0;
        if (popover) {
            setTo = popover === 'open';
        } else {
            setTo = !showingPopover;
        }
        setShowingPopover(setTo);
    };

    const eventToggle = (
        e: { target?: any; preventDefault?: any },
        popover: PopoverStateValues = void 0
    ) => {
        e.preventDefault();
        togglePopover(popover);
    };

    const keyDownToggle = (e: KeyboardEvent, popover: PopoverStateValues = void 0) => {
        const { code, which } = e;
        if (code === 'Space' || code === 'Enter' || which === 32 || which === 13) {
            eventToggle(e, popover);
        }
    };

    const handleClickInsideAnchor = (e: TargetedEvent) => eventToggle(e);

    const handleClickInsidePopover = (e: TargetedEvent) => eventToggle(e, 'close');

    const handleKeyDownInsideAnchor = (e: TargetedEvent) =>
        keyDownToggle(e as KeyboardEvent);

    const handleKeyDownInsidePopover = () => keyDownToggle(undefined);

    const handleClickOutsidePopover = (e: TargetedEvent) => {
        if (
            showingPopover &&
            e.target !== anchorRef &&
            e.target.parentNode !== anchorRef
        ) {
            togglePopover('close');
        }
    };

    const handleEscapeKey = (e: { keyCode?: number; key: string }) => {
        const { key, keyCode } = e;
        const shouldDismiss =
            'key' in e ? key === 'Escape' || key === 'Esc' : keyCode === 27;

        if (shouldDismiss && showingPopover) {
            togglePopover('close');
        }
    };

    const loadMachineKeys = async () => {
        await props.fetchMachineKeys();
    };

    useEffect(() => {
        const { reload } = props;
        const user = Auth.target(AuthTargetedTypes.USER);
        if (Auth.isLoggedIn()) {
            reload(user);
            loadMachineKeys();
        }
        document.addEventListener('click', handleClickOutsidePopover);
        document.addEventListener('keyup', handleEscapeKey);

        // returned function will be called on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutsidePopover);
            document.removeEventListener('keyup', handleEscapeKey);
        };
    }, []);

    const machineKeys = Auth.target(AuthTargetedTypes.API_KEYS);
    const {
        currentUser: { account, apiKey: { adminAccess } = apiKeyDefaults },
        apiKeys,
    } = props;
    const loggedIn = Auth.isLoggedIn();

    if (!loggedIn) {
        return (
            <div>
                <HeaderLink text="Sign in" to="/login" />
            </div>
        );
    }

    return (
        <div
            tabIndex={0}
            role="button"
            className="logged-in"
            onKeyDown={handleKeyDownInsideAnchor}
            onClick={handleClickInsideAnchor}
        >
            <div className="anchor" ref={anchorRef}>
                <Icon iconName="account-circle" />
                <div>{formatFullNameFromToken(account)}</div>
            </div>
            {showingPopover && (
                <div
                    tabIndex={0}
                    role="button"
                    className="popover"
                    onKeyDown={handleKeyDownInsidePopover}
                    onClick={handleClickInsidePopover}
                >
                    <span className="rec-sr-only">Account Info</span>
                    <ul>
                        <li>
                            <RidbLink to="/profile">Profile</RidbLink>
                        </li>
                        {(!!apiKeys?.length || !!machineKeys?.length) && (
                            <li>
                                <RidbLink className="no-wrap" to="/machine-tokens">
                                    Machine API Tokens
                                </RidbLink>
                            </li>
                        )}
                        {adminAccess && (
                            <li>
                                <RidbLink className="no-wrap" to="/admin">
                                    Admin Users
                                </RidbLink>
                            </li>
                        )}
                        <li>
                            <Button appearance="link" onClick={handleLogout}>
                                Logout
                            </Button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state: AccountInfoState) => ({
    currentUser: getCurrentUser(state),
    apiKeys: getMachineKeys(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
            logout: logoutAction,
            reload: reloadAction,
            updateUserSearchTerm: updateUserSearchTermAction,
            fetchMachineKeys: fetchMachineKeysAction,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
