/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { makeAsyncAction } from '../shared/utils/async';
import { FETCH_PREVIEW } from '../shared/constants/actions';
import API from '../shared/api';

export const fetchPreview = makeAsyncAction(
    FETCH_PREVIEW,
    (type, id) => () => API.fetchPreview(type, id),
    'facility'
);
