/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { TextArea } from 'sarsaparilla';
import {
    getCssClassesFromProps,
    getIdFromProps,
    getLabelFromProps,
    getMaxCharacterTextFromProps,
    getValidationCssClassesFromProps,
    getValidationListFromProps,
    IWrappedInputsBaseProps,
} from './wrappedInputs';
import '../../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';
import '../../../../scss/components/StewardAssetEditPage/_WrappedInputs.scss';

interface TextBoxInputProps extends IWrappedInputsBaseProps {
    disabled?: boolean;
    readOnly?: boolean;
    maxChar?: number;
    value?: any;
    fullWidth?: boolean;
}

function AssetEditTextBoxInput(props: TextBoxInputProps) {
    const {
        id: propsId = null,
        field,
        disabled = false,
        value = '',
        required = false,
        validation: propsValidation = [],
        readOnly = false,
        onChange,
        label: propsLabel,
        maxChar: propsMaxChar,
        fullWidth,
    } = props;
    const id = getIdFromProps(propsId, field, 'text-box-');
    const label = getLabelFromProps(propsLabel, id);
    const maxChar = getMaxCharacterTextFromProps(propsMaxChar);
    const validation = getValidationListFromProps(propsValidation);
    const validationCSS = getValidationCssClassesFromProps(propsValidation);
    const css = getCssClassesFromProps(required, fullWidth, validationCSS);

    return (
        <div className={css}>
            {maxChar}
            <TextArea
                label={label}
                id={id}
                data-field={field}
                isDisabled={disabled}
                readOnly={readOnly}
                maxLength={+maxChar || undefined}
                value={value}
                onChange={onChange}
            />
            {validation}
        </div>
    );
}

export default AssetEditTextBoxInput;
