/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { Globals } from '../globals';
import configurePath = Globals.configurePath;

type RidbLinkProps = {
    to: string;
    children?: any;

    [key: string]: any;
};

function RidbLink(props: RidbLinkProps) {
    return (
        <Link {...props} to={configurePath(props.to)}>
            {props.children}
        </Link>
    );
}

export default RidbLink;
