/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { connect } from 'react-redux';
import TextInputComponent from '../../../components/StewardAsset/StewardAssetEdit/inputs/AssetEditTextInput';
import TextBoxInputComponent from '../../../components/StewardAsset/StewardAssetEdit/inputs/AssetEditTextBoxInput';
import SelectInputComponent from '../../../components/StewardAsset/StewardAssetEdit/inputs/AssetEditSelectInput';
import FileInputComponent from '../../../components/StewardAsset/StewardAssetEdit/inputs/AssetEditFileInput';
import { getChangesValidation } from '../../../reducers/changes';

type OwnProps = { changeRef: any; field: string };
const mapStateToProps = (state, ownProps: Partial<OwnProps>) => ({
    validation: getChangesValidation(state, ownProps.changeRef, ownProps.field),
});

const TextInput = connect(mapStateToProps)(TextInputComponent);
const TextBoxInput = connect(mapStateToProps)(TextBoxInputComponent);
const SelectInput = connect(mapStateToProps)(SelectInputComponent);
const FileInput = connect(mapStateToProps)(FileInputComponent);

export { TextInput, TextBoxInput, SelectInput, FileInput };
