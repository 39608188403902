/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { TextField } from 'sarsaparilla';
import {
    getCssClassesFromProps,
    getIdFromProps,
    getLabelFromProps,
    getValidationCssClassesFromProps,
    getValidationListFromProps,
    IWrappedInputsBaseProps,
} from './wrappedInputs';
import { toStringSafe } from '../../../../shared/utils/string';
import '../../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';

interface TextInputProps extends IWrappedInputsBaseProps {
    disabled?: boolean;
    readOnly?: boolean;
    value?: any;
    fullWidth?: boolean;
    customValidation?: any[];
}

function AssetEditTextInput(props: TextInputProps) {
    const {
        id: propsId = null,
        field,
        disabled = false,
        value = '',
        validation: propsValidation = [],
        readOnly = false,
        onChange,
        label: propsLabel,
        required,
        fullWidth,
        customValidation = [],
    } = props;
    const id = getIdFromProps(propsId, field, 'text-');
    const label = getLabelFromProps(propsLabel, id);
    const finalValidation = [...customValidation, ...propsValidation].filter(
        (item) => !!item
    );
    const validation = getValidationListFromProps(finalValidation);
    const validationCSS = getValidationCssClassesFromProps(propsValidation);
    const css = getCssClassesFromProps(required, fullWidth, validationCSS);

    return (
        <div className={css}>
            {label}
            <TextField
                label=""
                id={id}
                data-field={field}
                value={toStringSafe(value)}
                disabled={disabled}
                readOnly={readOnly}
                onChange={onChange}
            />
            {validation}
        </div>
    );
}

export default AssetEditTextInput;
