/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import '../scss/containers/_LoginPage.scss';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom-v5-compat';

import {
    HelmetWrapper,
    MfaCodeForm,
    SignInFormContainer,
    SkipNavContent,
    Text,
} from 'sarsaparilla';

import { getCurrentUser } from '../reducers/currentUser';
import { Globals } from '../shared/globals';
import AlertsContainer from '../shared/containers/AlertsContainer';
import { type Session, login, mfaLogin } from '../shared/utils/login';

import signUpUrl = Globals.signUpUrl;
import forgotUrl = Globals.forgotUrl;

// From sarsa
type LoginData = {
    username: string;
    password: string;
    userAgent: string;
    redirectUrl?: string | undefined;
};

export default function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const showMfa = useSelector((state: any) => state.modal.showMfaCodeModal);
    const hasError = useSelector((state: any) => Boolean(state.auth?.loginError));
    const isLockedOut = useSelector((state: any) => Boolean(state.auth?.lockout));
    const serverError = useSelector((state: any) => state.auth?.errorText);
    const isLoggedIn = useSelector((state: any) => getCurrentUser(state).loggedIn);
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState(null);

    if (isLoggedIn) {
        navigate('/landing');
    }

    const onCreateAccount = () => {
        window.location.href = signUpUrl;
    };

    const onResetAccount = () => {
        window.location.href = forgotUrl;
    };

    const onLogin = async (data: LoginData) => {
        try {
            setLoading(true);

            await dispatch(login(data));

            setLoading(false);
        } catch {
            setLoading(false);
        }
    };

    const onMfaLogin = async (v: string, authType: string) => {
        try {
            const value = authType === 'mfaCode' ? v?.replace(/\D/g, '') : v;

            setLoading(true);

            if (authType === 'mfaCode' && value.length !== 6) {
                setErrorText('unknown code');
                setLoading(false);
                return;
            }

            await mfaLogin({ token: value, userAgent: navigator.userAgent })(dispatch);

            setLoading(false);
            window.location.assign('/landing');
        } catch {
            setLoading(false);
        }
    };

    const mfaErrorMessage =
        hasError || errorText ? (
            <>
                Are you sure you have the right code? Please double check your code, and
                try again. You can{' '}
                <a href={`${process.env.SITE_ROOT}/reset-password`}>Reset Password</a>.
            </>
        ) : isLockedOut ? (
            <>
                This account has been locked out for 30 minutes due to three or more
                failed login attempts. To access your account before then, check your
                e-mail for a magic login link or{' '}
                <a href={`${process.env.SITE_ROOT}/reset-password`}>Reset Password</a>. If
                you do not receive your magic log in email, please check your spam folder.
            </>
        ) : null;

    return (
        <SkipNavContent>
            <HelmetWrapper title="RIDB Login" />
            <main role="main">
                <div className="login-page">
                    <div className="alerts-box">
                        <AlertsContainer group="" />
                    </div>
                    <div className="modal-box">
                        {showMfa ? (
                            <MfaCodeForm
                                siteName={process.env.SITE_NAME}
                                processing={loading}
                                errorMessage={mfaErrorMessage}
                                setErrorText={setErrorText}
                                createAccount={onCreateAccount}
                                onReset={onResetAccount}
                                confirmSignIn={onMfaLogin}
                                isResetPasswordFlow={false}
                            >
                                <Text
                                    className="login-text-block"
                                    fontWeight="semibold"
                                    align="center"
                                >
                                    Use your {process.env.SITE_NAME} account email to Sign
                                    In.
                                </Text>
                            </MfaCodeForm>
                        ) : (
                            <SignInFormContainer
                                onCreateAccountClick={onCreateAccount}
                                onResetPasswordClick={onResetAccount}
                                login={onLogin}
                                showCaptchaMessage={false}
                                redirectUrl="/landing"
                                hasError={hasError}
                                serverError={serverError}
                                isLockedOut={isLockedOut}
                            >
                                <Text
                                    className="login-text-block"
                                    fontWeight="semibold"
                                    align="center"
                                >
                                    Use your {process.env.SITE_NAME} account email to Sign
                                    In.
                                </Text>
                            </SignInFormContainer>
                        )}
                    </div>
                </div>
            </main>
        </SkipNavContent>
    );
}
