/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { get } from 'lodash';
import { Label } from 'sarsaparilla';
import '../../../scss/components/_TextDisplay.scss';
import { Globals } from '../../../shared/globals';
import mergeDefaults = Globals.mergeDefaults;
import { getLabelFromProps } from '../StewardAssetEdit/inputs/wrappedInputs';

const defaultProps = { value: '' };

type TextDisplayProps = {
    label: string;
    field: string;
    value: any;
    arrayIdField: string;
    arrayFields: string[];
    fullWidth: boolean;
};

function TextDisplay(props: Partial<TextDisplayProps> = defaultProps) {
    const {
        field,
        label: propsLabel,
        value,
        fullWidth,
        arrayIdField,
        arrayFields,
    } = mergeDefaults(props, defaultProps);
    const id = `mod_${field.replace('.', '_')}`;
    const label = getLabelFromProps(propsLabel, id);
    const boolValue = value ? 'Yes' : 'No';
    const css = `text-display ${fullWidth ? 'full-width' : ''}`;
    return (
        <div className={css}>
            {label}
            {arrayFields && arrayIdField && value.length >= 0 ? (
                value.map((val) => (
                    <div key={get(val, arrayIdField)} className="array-display">
                        {arrayFields.map((field) => (
                            <p key={field}>{get(val, field)}</p>
                        ))}
                    </div>
                ))
            ) : (
                <p>
                    {typeof value === 'boolean'
                        ? boolValue
                        : value instanceof Object
                          ? value[propsLabel]
                          : value}
                </p>
            )}
        </div>
    );
}
export default TextDisplay;
