/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { uniqueId } from 'lodash';
import { Translator } from './translator';
import { Globals } from '../../globals';
import { decodeMediaKeys, encodeMediaKeys } from '../../constants/asset/media';
import AnyObject = Globals.AnyObject;

const keyConfig = {
    main: {
        encode: encodeMediaKeys,
        decode: decodeMediaKeys,
    },
};

class Media extends Translator {
    decode = (record: AnyObject) => {
        const { decode: decodeKeys } = this.keys.main;
        return this.translate(decodeKeys, record, { _key: uniqueId('media-') });
    };

    encode = (record: { image?: any }) => {
        const { encode: encodeKeys } = this.keys.main;
        if (record.image) {
            const formData = new FormData();
            for (const key in encodeKeys) {
                if (record[key] !== null && record[key] !== undefined) {
                    formData.append(encodeKeys[key], record[key]);
                }
            }
            // return the form data and options for multipart form
            return [formData, { 'Content-Type': 'multipart/form-data' }];
        }
        return [this.translate(encodeKeys, record), {}];
    };
}

export default new Media(keyConfig);
