/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { uniqueId } from 'lodash';
import { Translator } from './translator';
import { decodeLinkKeys, encodeLinkKeys } from '../../constants/asset/links';
import { Globals } from '../../globals';
import AnyObject = Globals.AnyObject;

const keyConfig = {
    main: {
        encode: encodeLinkKeys,
        decode: decodeLinkKeys,
    },
};

class Link extends Translator {
    decode = (record: AnyObject) => {
        const { decode: decodeKeys } = this.keys.main;
        return this.translate(decodeKeys, record, { _key: uniqueId('link-') });
    };

    encode = (record: AnyObject) => {
        const { encode: encodeKeys } = this.keys.main;
        return this.translate(encodeKeys, record);
    };
}

export default new Link(keyConfig);
