/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { IAssetDisplayFields } from '../../types/asset/asset';

export const recAreaDecodeKeys = {
    RecAreaID: 'id',
    OrgRecAreaID: 'orgRefId',
    ParentOrgID: 'orgId',
    RecAreaName: 'name',
    RecAreaDescription: 'description',
    RecAreaFeeDescription: 'feeDescription',
    RecAreaDirections: 'directions',
    RecAreaAccessibilityText: 'accessibilityText',
    RecAreaPhone: 'phone',
    RecAreaEmail: 'email',
    RecAreaReservationURL: 'reservationUrl',
    RecAreaMapURL: 'mapUrl',
    RecAreaLongitude: 'longitude',
    RecAreaLatitude: 'latitude',
    StayLimit: 'stayLimit',
    Keywords: 'keywords',
    Enabled: 'enabled',
    Reservable: 'reservable',
    LastUpdatedDate: 'lastUpdatedDate',
};

export const recAreaEncodeKeys = {
    id: 'recAreaID',
    orgId: 'orgID',
    orgRefId: 'legacyRecAreaIDOrg',
    name: 'recAreaName',
    description: 'recAreaDescription',
    feeDescription: 'recAreaFeeDescription',
    directions: 'recAreaDirections',
    accessibilityText: 'recAreaAccessibilityText',
    phone: 'recAreaPhone',
    email: 'recAreaEmail',
    reservationUrl: 'recAreaReservationURL',
    mapUrl: 'recAreaMapURL',
    longitude: 'recAreaLongitude',
    latitude: 'recAreaLatitude',
    stayLimit: 'stayLimit',
    enabled: 'enabled',
    reservable: 'reservable',
    keywords: 'keywords',
};

export const recAreaDecodeAddressKeys = {
    RecAreaAddressID: 'id',
    RecAreaID: 'assetId',
    RecAreaAddressType: 'type',
    RecAreaStreetAddress1: 'address1',
    RecAreaStreetAddress2: 'address2',
    RecAreaStreetAddress3: 'address3',
    City: 'city',
    PostalCode: 'postalCode',
    AddressStateCode: 'stateCode',
    AddressCountryCode: 'countryCode',
    LastUpdatedDate: 'lastUpdatedDate',
};

export const recAreaEncodeAddressKeys = {
    id: 'recAreaAddressID',
    assetId: 'recAreaID',
    type: 'recAreaAddressType',
    address1: 'recAreaStreetAddress1',
    address2: 'recAreaStreetAddress2',
    address3: 'recAreaStreetAddress3',
    city: 'city',
    postalCode: 'postalCode',
    stateCode: 'addressStateCode',
    countryCode: 'addressCountryCode',
};

export const recAreaDecodeActivityKeys = {
    ActivityID: 'activityId',
    ActivityParentID: 'parentActivityId',
    RecAreaID: 'assetId',
    ActivityName: 'name',
    RecAreaActivityDescription: 'description',
    RecAreaActivityFeeDescription: 'feeDescription',
};

export const recAreaEncodeActivityKeys = {
    activityId: 'activityID',
    assetId: 'recAreaID',
};

export const recAreaAssetTypeDetails = {
    name: 'Rec Area',
    longName: 'Recreation Area',
    singular: 'recarea',
    plural: 'recareas',
    activity: 'recareaactivity',
    activities: 'recareaactivities',
    address: 'recareaaddreess',
    addresses: 'recareaaddresses',
    link: 'recarealink',
    links: 'recarealinks',
    media: 'recareamedia',
    event: 'recareaevent',
    events: 'recareaevents',
};

export const recAreaDisplayFields: IAssetDisplayFields[] = [
    {
        label: 'Rec Area Name',
        field: 'name',
    },
    {
        label: 'Rec Area ID',
        field: 'id',
    },
    {
        label: 'Email',
        field: 'email',
    },
    {
        label: 'Fee Description',
        field: 'feeDescription',
    },
    {
        label: 'Legacy ID',
        field: 'legacyId',
    },
    {
        label: 'Legacy ID Organization',
        field: 'legacyIdOrg',
    },
    {
        label: 'Latitude',
        field: 'latitude',
    },
    {
        label: 'Longitude',
        field: 'longitude',
    },
    {
        label: 'Phone',
        field: 'phone',
    },
    {
        label: 'Enabled',
        field: 'enabled',
    },
    {
        label: '',
        field: '',
    },
    {
        label: 'Organization',
        field: 'organizations',
        arrayIdField: 'id',
        arrayFields: ['name'],
    },
    {
        label: 'Activities',
        field: 'activities',
        arrayIdField: 'activityId',
        arrayFields: ['name'],
    },
    {
        label: 'Description',
        fullWidth: true,
        field: 'description',
    },
    {
        label: 'Directions',
        fullWidth: true,
        field: 'directions',
    },
    {
        label: `ADA Accessibility Info`,
        fullWidth: true,
        field: 'accessibilityText',
    },
    {
        label: 'Keywords',
        fullWidth: true,
        field: 'keywords',
    },
    {
        label: 'Stay Limit',
        fullWidth: true,
        field: 'stayLimit',
    },
    {
        label: 'Addresses',
        fullWidth: true,
        field: 'addresses',
        arrayIdField: 'id',
        arrayFields: [
            'address1',
            'address2',
            'address3',
            'city',
            'stateCode',
            'postalCode',
            'countyCode',
        ],
    },
];
