/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import memoize from 'memoize-one';
import { Button, Icon, Select, SelectOption } from 'sarsaparilla';
import { debounce } from 'lodash';
import { fetchOrganizations } from '../actions/organization';
import { fetchActivities } from '../actions/activities';
import { saveSearch } from '../actions/search';
import { getOrganizations } from '../reducers/organizations';
import { getActivities } from '../reducers/activities';
import { getState } from '../reducers';
import { unitedStates } from '../shared/constants/unitedStates';
import { getSearch } from '../reducers/search';
import { flattenOrgs, orgsToOptions } from '../shared/utils/organization';
import IconTextInput from '../shared/components/IconTextInput';
import InlineCheckboxInput from '../shared/components/InlineCheckboxInput';
import { StateStatus } from '../shared/utils/async';
import { Globals } from '../shared/globals';
import AnyFunction = Globals.AnyFunction;
import TargetedEvent = Globals.TargetedEvent;
import emptyFunction = Globals.emptyFunction;
import mergeDefaults = Globals.mergeDefaults;
import '../scss/containers/_SearchForm.scss';
import { ISearchQuery } from '../shared/types/search';

const defaultProps = {
    inline: false,
    showLimitSelect: false,
    searchOnChange: false,
};

type SearchFormProps = {
    searchQuery: ISearchQuery;
    states?: any;
    searchOnChange?: boolean;
    fetchOrganizations?: () => any;
    fetchActivities?: (obj: Partial<{ sort: string }>) => any;
    onSearch?: AnyFunction;
    saveSearch?: AnyFunction;
    activities?: any[];
    inline?: AnyFunction | boolean;
    showLimitSelect?: AnyFunction | boolean;
    organizations?: AnyFunction;
    dataLength?: number;
};

type SearchFormState = {
    query: any;
    orgId: string;
};

export class SearchForm extends PureComponent<SearchFormProps, Partial<SearchFormState>> {
    state = {
        query: this.props.searchQuery,
        orgId: '',
    };

    readonly getOrgOptions = memoize((organizations) =>
        organizations ? orgsToOptions(flattenOrgs(organizations)) : []
    );

    readonly itemsPerPage = [10, 20, 30, 40, 50];

    constructor(props) {
        super(props || defaultProps);

        this.searchOnChange = debounce(this.searchOnChange.bind(this), 350);
    }

    componentDidMount() {
        const {
            states: { organizations, activities },
            fetchActivities,
            fetchOrganizations,
        } = mergeDefaults(this.props, defaultProps);
        if (organizations === StateStatus.UNLOADED) {
            fetchOrganizations();
        }
        if (activities === StateStatus.UNLOADED) {
            fetchActivities({ sort: 'name' });
        }
    }

    componentDidUpdate(prevProps: SearchFormProps) {
        const { searchQuery: currentSearchQuery } = this.props;
        const { searchQuery: previousSearchQuery } = prevProps;
        const { query } = this.state;
        if (previousSearchQuery !== currentSearchQuery && currentSearchQuery !== query) {
            this.setState({ query: currentSearchQuery });
        }
    }

    setStateCode = (event: TargetedEvent) => {
        let { value } = event.target;

        if (value === '') {
            value = null;
        }

        this.setState(
            (prevState) => ({
                ...prevState,
                query: { ...prevState.query, state: value },
            }),
            this.searchOnChange
        );
    };

    setActivity = (event: TargetedEvent) => {
        let { value } = event.target;

        if (value === '') {
            value = null;
        }

        this.setState(
            (prevState) => ({
                ...prevState,
                query: { ...prevState.query, activity: value },
            }),
            this.searchOnChange
        );
    };

    setOrganizations = (event: TargetedEvent) => {
        const { value } = event.target;

        this.setState(
            (prevState) => ({
                ...prevState,
                orgId: value,
                query: { ...prevState.query, orgIds: value === '' ? null : [value] },
            }),
            this.searchOnChange
        );
    };

    setTerms = (terms: string) => {
        this.setState(
            (prevState) => ({
                ...prevState,
                query: { ...prevState.query, terms },
            }),
            this.searchOnChange
        );
    };

    searchAssetType = (event: TargetedEvent<{ checked: boolean }>) => {
        const { value, checked } = event.target;

        this.setState((prevState) => {
            const { query } = prevState;
            const { assetTypes } = query;

            if (checked) {
                return {
                    ...prevState,
                    query: { ...query, assetTypes: [...assetTypes, value] },
                };
            }
            return {
                ...prevState,
                query: { ...query, assetTypes: assetTypes.filter((v) => v !== value) },
            };
        }, this.searchOnChange);
    };

    setLimit = (event: TargetedEvent) => {
        const { value } = event.target;
        const limit = Math.min(50, Math.max(10, parseInt(value, 10)));
        this.setState(
            (prevState) => ({
                ...prevState,
                query: { ...prevState.query, limit },
            }),
            this.searchOnChange
        );
    };

    searchOnChange = () => {
        const { searchOnChange } = this.props;
        if (searchOnChange) {
            this.goSearch();
        }
    };

    goSearch = () => {
        const { onSearch = emptyFunction, saveSearch } = this.props;
        const { query } = this.state;
        saveSearch(query).then(onSearch);
    };

    handleKeyPress = (e) => {
        if (e.key === 'Enter' && this.state.query.terms) {
            this.goSearch();
        }
    };

    render() {
        const {
            activities,
            inline,
            showLimitSelect,
            searchOnChange,
            organizations,
            dataLength = 0,
        } = this.props;
        const {
            query: { state, activity, terms, limit },
            orgId,
        } = this.state;
        const orgOptions = this.getOrgOptions(organizations);
        const inlinedRow = !inline ? `row stacked` : 'row';

        return (
            <div role="form" className="search-form">
                <div className="row">
                    <div className="cell">
                        <InlineCheckboxInput
                            id="search-asset-recareas"
                            value="recarea"
                            label="Search Recreation Areas"
                            defaultChecked
                            onChange={this.searchAssetType}
                        />
                    </div>
                    <div className="cell">
                        <InlineCheckboxInput
                            id="search-asset-facility"
                            value="facility"
                            label="Search Facilities"
                            defaultChecked
                            onChange={this.searchAssetType}
                        />
                    </div>
                    {showLimitSelect ? (
                        <div className="align-right cell biggest">
                            <Select
                                label="Records Per Page"
                                id="records-per-page"
                                name="records-per-page"
                                value={limit.toString()}
                                className="width-10"
                                onChange={this.setLimit}
                            >
                                {this.itemsPerPage.map((i) => (
                                    <SelectOption key={i} value={i.toString()} label={i}>
                                        {i}
                                    </SelectOption>
                                ))}
                            </Select>
                            <div className="records-per-page">
                                &nbsp; records per page
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className={inlinedRow}>
                    <div className="cell">
                        <Select
                            label="Select State"
                            id="search-states"
                            name="search-states"
                            value={state || ''}
                            onChange={this.setStateCode}
                        >
                            <SelectOption value="">Select State</SelectOption>
                            {unitedStates.map(({ abbreviation: value, label }) => (
                                <SelectOption key={value} value={value.toString()}>
                                    {label}
                                </SelectOption>
                            ))}
                        </Select>
                    </div>
                    <div className="cell">
                        <Select
                            label="Select Activity"
                            id="search-activities"
                            name="search-activities"
                            value={activity || ''}
                            onChange={this.setActivity}
                        >
                            <SelectOption value="">Select Activity</SelectOption>
                            {activities.map(({ id, name }) => (
                                <SelectOption key={id} value={id.toString()}>
                                    {name}
                                </SelectOption>
                            ))}
                        </Select>
                    </div>
                    <div className="cell">
                        <Select
                            label="Select Organizations"
                            id="search-organizations"
                            name="search-organizations"
                            value={orgId}
                            onChange={this.setOrganizations}
                        >
                            <SelectOption value="">Select Organization</SelectOption>
                            {orgOptions.map(({ value, label }) => (
                                <SelectOption key={value} value={value.toString()}>
                                    {label}
                                </SelectOption>
                            ))}
                        </Select>
                    </div>
                    <div className="cell">
                        <label htmlFor="search-terms" className="rec-sr-only">
                            Search Text
                        </label>
                        <IconTextInput
                            isInvalid={!terms && !dataLength}
                            onKeyPress={this.handleKeyPress}
                            id="search-terms"
                            value={terms || ''}
                            placeholder="Search Text"
                            iconLeft={() => <Icon iconName="search" />}
                            onChange={this.setTerms}
                        />
                    </div>
                </div>
                {searchOnChange ? null : (
                    <div className="row align-right">
                        <Button isDisabled={!terms} onClick={this.goSearch}>
                            Search
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    searchQuery: getSearch(state),
    organizations: getOrganizations(state),
    activities: getActivities(state),
    states: getState(state, 'organizations', 'activities'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            saveSearch,
            fetchOrganizations,
            fetchActivities,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
