/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    FETCH_PREVIEW,
    PUBLISH_PREVIEW,
    RIDB_ACTION_PREFIX,
} from '../shared/constants/actions';
import { asyncDefaults, makeAsyncReducer, mergeReducers } from '../shared/utils/async';

const fetchPreview = makeAsyncReducer(FETCH_PREVIEW);

export const getPreview = (state) => state.portal.preview?.data;

export default mergeReducers(asyncDefaults([]), RIDB_ACTION_PREFIX, fetchPreview);
