/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import get from 'lodash/get';
import { Autosuggest } from 'sarsaparilla';
import { Item, type Key } from 'react-stately';
import { IAsset } from '../../../shared/types/asset/asset';
import { ISearchQuery } from '../../../shared/types/search';
import { Globals } from '../../../shared/globals';
import AnyFunction = Globals.AnyFunction;
import { StateStatus } from '../../../shared/utils/async';
import { getState } from '../../../reducers';
import {
    fetchAssetsForAutoComplete,
    resetAssetsForAutoComplete,
} from '../../../actions/asset';
import { getAssetsForAutoComplete } from '../../../reducers/assetsForAutoComplete';

type SuggestSearchProps = {
    id: string;
    value: IAsset;
    placeholder: string;
    query: ISearchQuery;
    field: string;
    idField: string;
    onChange: AnyFunction;
    suggestions: Suggestion[];
    states: { [key: string]: number };
    onSearch: AnyFunction;
    onClearSuggestions: AnyFunction;
};

type SuggestSearchState = {
    searchText: string;
};

type Suggestion = {
    enabled: boolean;
    id: string;
    name: string;
    org_id: string;
    org_name: string;
    reservable: boolean;
    type: string;
    updated_at: string;
};

export function SuggestSearch({
    id,
    value,
    placeholder,
    query,
    field,
    idField,
    onChange,
    suggestions = [],
    states,
    onSearch,
    onClearSuggestions,
}: SuggestSearchProps) {
    const [searchText, setSearchText] = useState(get(value, 'value.name', ''));

    useEffect(() => {
        if (value) {
            setSearchText(value.name);
            onSuggestionsFetchRequested(value.name);
        }
    }, [value]);

    const onSuggestionsFetchRequested = (terms: string) => {
        const { assetsForAutoComplete } = states;
        if (assetsForAutoComplete !== StateStatus.LOADING) {
            onSearch({ ...query, terms });
        }
    };

    const getSuggestionValue = ({ name }: { name: string }) => name;

    const renderSuggestion = (
        suggestion: any,
        { isHighlighted }: { isHighlighted?: boolean }
    ): JSX.Element => {
        const className = isHighlighted ? 'highlighted' : '';
        return <div className={className}>{getSuggestionValue(suggestion)}</div>;
    };

    const onSuggestionSelected = (suggestion: Suggestion): void => {
        onChange({ target: { value: suggestion, dataset: { field, idField } } });
    };

    const handleOnInputChange = (text: string) => {
        setSearchText(text);
        onSuggestionsFetchRequested(text);
    };

    const handleOnSelect = (key: Key) => {
        const selectedItem = suggestions.find((option) => option.id === key);
        if (selectedItem) {
            onSuggestionSelected(selectedItem);
        }
    };

    /* It's unclear whether or not it's not okay to have an empty field, as there is no validation for empty values,
     * but the "Changes" status doesn't track empty field as a change.
     * This onBlur function is used to set the value to the previous value if the field is empty.
     */
    const handleOnBlur = () => {
        if (!searchText && value) {
            handleOnSelect(value.id);
        }
    };

    return (
        <Autosuggest
            allowsEmptyCollection
            id={id}
            label="Parent Rec Area"
            isLabelVisible={false}
            menuTrigger="focus"
            placeholder={placeholder}
            items={suggestions}
            inputValue={searchText}
            onSelect={handleOnSelect}
            onInputChange={handleOnInputChange}
            onClear={onClearSuggestions}
            onBlur={handleOnBlur}
        >
            {(item) => {
                const isHighlighted = item.id === value?.id;
                return (
                    <Item textValue={getSuggestionValue(item)}>
                        {renderSuggestion(item, { isHighlighted })}
                    </Item>
                );
            }}
        </Autosuggest>
    );
}
const mapStateToProps = (state: SuggestSearchState) => {
    return {
        suggestions: getAssetsForAutoComplete(state),
        states: getState(state, 'assetsForAutoComplete'),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            onSearch: fetchAssetsForAutoComplete,
            onClearSuggestions: resetAssetsForAutoComplete,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SuggestSearch);
