/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { uniqueId } from 'lodash';
import { Label } from 'sarsaparilla';
import React from 'react';
import { Globals } from '../../../../shared/globals';
import AnyFunction = Globals.AnyFunction;

export interface IWrappedInputsBaseProps {
    id?: string;
    label?: string;
    field?: string;
    validation?: string[];
    onChange: AnyFunction;
    required?: boolean;
}

export const getIdFromProps = (propsId: string, field: string, prefix: string) =>
    propsId || `${uniqueId(prefix)}_${field.replace('.', '_')}`;

export const getCssClassesFromProps = (
    required = false,
    fullWidth = false,
    classes = ''
) =>
    `text-input ${classes} ${required ? 'required' : ''} ${fullWidth ? 'full-width' : ''}`;

export const getLabelFromProps = (propsLabel: string, id: string = uniqueId()) =>
    !propsLabel ? void 0 : <Label htmlFor={id}>{propsLabel}</Label>;

export const getValidationCssClassesFromProps = (validation: any[]) =>
    validation && validation.length > 0 ? 'validation-problems' : '';

export const getValidationListFromProps = (validation: any[]) => {
    if (!validation) {
        return null;
    }
    const colorStyles = { color: 'red' };
    const blockStyles = { display: 'block' };
    return (
        <div style={blockStyles}>
            {validation.map((err) => (
                <div className="sarsa-field-error-message" key={err}>
                    <span style={colorStyles}>*</span>
                    {err}
                </div>
            ))}
        </div>
    );
};

export const getMaxCharacterTextFromProps = (maxChar: any) =>
    !maxChar ? null : <p>{maxChar} character limit</p>;
