/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { uniqueId, get } from 'lodash';
import { Globals } from '../../../../shared/globals';
import TargetedEvent = Globals.TargetedEvent;
import {
    getCssClassesFromProps,
    getLabelFromProps,
    getValidationCssClassesFromProps,
    getValidationListFromProps,
    IWrappedInputsBaseProps,
} from './wrappedInputs';
import '../../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';

interface FileInputProps extends IWrappedInputsBaseProps {
    fileTypes?: string;
    value?: any;
}

function AssetEditFileInput(props: FileInputProps) {
    const {
        id: propsId = uniqueId(),
        value,
        field,
        validation: propsValidation = [],
        onChange,
        label: propsLabel,
        required,
        fileTypes,
    } = props;

    const handleChange = (event: TargetedEvent) => {
        const {
            target: { files },
        } = event;
        if (!files) {
            onChange(event);
        }

        onChange({
            target: {
                dataset: { field },
                value: files[0],
            },
        });
    };

    const label = getLabelFromProps(propsLabel, propsId);
    const validation = getValidationListFromProps(propsValidation);
    const validationCSS = getValidationCssClassesFromProps(propsValidation);
    const css = getCssClassesFromProps(required, false, validationCSS);
    return (
        <div className={css}>
            {label}
            <input
                name={get(value, 'name')}
                type="file"
                id={propsId}
                accept={fileTypes}
                onChange={handleChange}
            />
            {validation}
        </div>
    );
}

export default AssetEditFileInput;
