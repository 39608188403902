/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

export const RIDB_ACTION_PREFIX = 'DEV_PORTAL_';
export const SAVE_ASSET = 'SAVE_ASSET';

const makeActionItem = (name: string) => `${RIDB_ACTION_PREFIX}${name}`;

export const INIT_REF = makeActionItem('INIT_REF');
export const REMOVE_REF = makeActionItem('REMOVE_REF');
export const REMOVE_REFS = makeActionItem('REMOVE_REFS');
export const UPDATE_CHANGE_ID = makeActionItem('UPDATE_CHANGE_ID');
export const UPDATE_REQUIRED_CHANGES = makeActionItem('UPDATE_REQUIRED_CHANGES');
export const ADD_PENDING_CHANGES = makeActionItem('ADD_PENDING_CHANGES');
export const CLEAR_PENDING_CHANGE = makeActionItem('CLEAR_PENDING_CHANGE');
export const CLEAR_PENDING_CHANGES = makeActionItem('CLEAR_PENDING_CHANGES');

export const FETCH_APIKEY = makeActionItem('FETCH_APIKEY');
export const GENERATE_APIKEY = makeActionItem('GENERATE_APIKEY');
export const FETCH_MACHINE_KEYS = makeActionItem('FETCH_MACHINE_KEYS');
export const GENERATE_MACHINE_KEYS = makeActionItem('GENERATE_MACHINE_KEYS');
export const DISABLE_MACHINE_KEY = makeActionItem('DISABLE_MACHINE_KEY');

export const LOGIN = makeActionItem('LOGIN');
export const LOGOUT = makeActionItem('LOGOUT');
export const RELOAD = makeActionItem('RELOAD');
export const UPDATE_CURRENT_USER = makeActionItem('UPDATE_CURRENT_USER');

export const FETCH_ORGANIZATIONS = makeActionItem('FETCH_ORGS');
export const FETCH_CHANGE_LOG_ITEMS = makeActionItem('FETCH_CHANGE_LOG_ITEMS');

export const SEARCH = makeActionItem('SEARCH');

export const FETCH_ASSET = makeActionItem('FETCH_ASSET');
export const RESET_ASSET = makeActionItem('RESET_ASSET');

export const FETCH_ASSETS = makeActionItem('FETCH_ASSETS');

export const FETCH_EVENTS = makeActionItem('FETCH_EVENTS');
export const RESET_EVENTS = makeActionItem('RESET_EVENTS');

export const FETCH_PREVIEW = makeActionItem('FETCH_PREVIEW');
export const PUBLISH_PREVIEW = makeActionItem('PUBLISH_PREVIEW');

export const FETCH_ASSETS_FOR_AUTOCOMPLETE = makeActionItem(
    'FETCH_ASSETS_FOR_AUTOCOMPLETE'
);
export const RESET_ASSETS_FOR_AUTOCOMPLETE = makeActionItem(
    'RESET_ASSETS_FOR_AUTOCOMPLETE'
);

export const FETCH_ACTIVITIES = makeActionItem('FETCH_ACTIVITIES');

export const SAVE_PROGRESS_START = makeActionItem('SAVE_PROGRESS_START');
export const SAVE_PROGRESS_STEP = makeActionItem('SAVE_PROGRESS_STEP');
export const SAVE_PROGRESS_CLEAR = makeActionItem('SAVE_PROGRESS_CLEAR');

export const SAVE_ADD_ERROR = makeActionItem('SAVE_ADD_ERROR');
export const SAVE_CLEAR_ERRORS = makeActionItem('SAVE_CLEAR_ERRORS');
export const SAVE_GET_ERRORS = makeActionItem('SAVE_GET_ERRORS');

export const VALIDATION_ADD_ERROR = makeActionItem('VALIDATION_ADD_ERROR');
export const VALIDATION_CLEAR_ERRORS = makeActionItem('VALIDATION_CLEAR_ERRORS');

export const USER_SEARCH_FETCH = makeActionItem('USER_SEARCH_FETCH');
export const USER_SEARCH_UPDATE = makeActionItem('USER_SEARCH_UPDATE');
export const USER_SEARCH_ADD_ERROR = makeActionItem('USER_SEARCH_ADD_ERROR');
export const USER_SEARCH_CLEAR_ERROR = makeActionItem('USER_SEARCH_CLEAR_ERROR');

export const FETCH_ACCOUNT = makeActionItem('FETCH_ACCOUNT');
export const ENABLE_DEVELOPER_ACCESS = makeActionItem('ENABLE_DEVELOPER_ACCESS');
export const UPDATE_ACCOUNT = makeActionItem('UPDATE_ACCOUNT');
