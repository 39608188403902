/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

export const searchDefaults = {
    assetTypes: ['recarea', 'facility'],
    activity: null,
    orgIds: [],
    state: null,
    terms: null,
    limit: 10,
    page: 0,
    sort: 'name',
};

export const noSearchResults =
    'There are no entries in the database matching your search query';
