/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HelmetWrapper, SkipNavLink } from 'sarsaparilla';
import { getCurrentUser } from '../reducers/currentUser';
import AccountInfo from './AccountInfo';
import HeaderLink from '../shared/components/HeaderLink';
import { apiKeyDefaults } from '../shared/constants/auth';
import { Globals } from '../shared/globals';
import getAssetPath = Globals.getAssetPath;
import '../scss/containers/_Header.scss';

export function Header(props) {
    const {
        currentUser: { apiKey: { writeAccess } = apiKeyDefaults },
    } = props;
    const imagePath = getAssetPath('img/recLogoLight.png');
    const logoAltText = `${process.env['SITE_NAME']} logo`;
    return (
        <header id="ridb-header" role="banner" className="ridb-header">
            <SkipNavLink />
            <HelmetWrapper title="RIDB" />
            <div className="logo">
                <img src={imagePath} alt={logoAltText} />
                <div className="h6">RIDB {process.env['RIDB_VERSION']}</div>
            </div>
            <HeaderLink to="/" text="Home" />
            <HeaderLink to="/docs" text="API Documentation" />
            <HeaderLink to="/download" text="Data Download" />
            <HeaderLink to="/standards" text="Data Standards" />
            {writeAccess && (
                <HeaderLink
                    text="Data Management"
                    menuItemStartActive
                    to="/data-steward"
                />
            )}
            <div className="menu-item">
                <AccountInfo />
            </div>
        </header>
    );
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
