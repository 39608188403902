/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { useState, useEffect } from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom-v5-compat';

import { SkipNavContent } from 'sarsaparilla';

import { fetchAsset } from '../../actions/asset';
import { getState } from '../../reducers';
import TextDisplay from '../../components/StewardAsset/StewardAssetDisplay/TextDisplay';
import LoadingWrapper from '../../shared/components/LoadingWrapper';
import { getAsset } from '../../reducers/asset';
import { StateStatus } from '../../shared/utils/async';
import { recAreaDisplayFields } from '../../shared/constants/asset/recArea';
import {
    FACILITY_URL,
    facilityDisplayFields,
} from '../../shared/constants/asset/facility';
import Alert from '../../shared/components/Alert';
import RidbLink from '../../shared/components/RidbLink';
import '../../scss/containers/_StewardAssetDisplayPage.scss';

const facilityNotFound = {
    id: '1',
    level: 'warning',
    title: 'Warning',
    message: 'Facility not found.',
};

export default function StewardAssetDisplayPage() {
    const dispatch = useDispatch();
    const { assetType, assetId } = useParams();

    const asset = useSelector((state) => getAsset(state) || {});
    const { asset: statesAsset } = useSelector((state) => getState(state, 'asset') || {});

    const [apiLoading, setApiLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    const assetNeedsUpdate = () => {
        return assetId && get(asset, 'id') !== assetId;
    };

    const fetchData = async () => {
        if (assetNeedsUpdate() && !apiLoading) {
            try {
                setApiLoading(true);
                // @ts-ignore dispatch is not correctly types
                dispatch(fetchAsset(assetType, assetId, { full: true }));
            } catch (e) {
                setNotFound(true);
            } finally {
                setApiLoading(false);
            }
        }
    };

    const getButtons = (reservable: boolean) => {
        const editInHubURL = `${process.env['HUB_EDIT_URL']}/${assetId}`;
        return (
            <div className="display-buttons">
                <RidbLink
                    className="rec-button-tertiary"
                    to={`/data-steward/${assetType}/search`}
                >
                    Back to search
                </RidbLink>
                {reservable ? (
                    <a
                        className="rec-button-primary left-margin-15"
                        target="_blank"
                        rel="noreferrer"
                        href={editInHubURL}
                    >
                        Edit in the Hub
                    </a>
                ) : (
                    <RidbLink
                        className="rec-button-primary left-margin-15"
                        to={`/data-steward/${assetType}/${assetId}/edit`}
                    >
                        Edit
                    </RidbLink>
                )}
            </div>
        );
    };

    useEffect(() => {
        fetchData();
    }, []);

    const displayFields =
        assetType === FACILITY_URL ? facilityDisplayFields : recAreaDisplayFields;

    const loading = statesAsset !== StateStatus.READY;
    const buttons = getButtons(get(asset, 'reservable', true));

    const getValue = (field: string) => {
        const val = get(asset, field, '');
        return typeof val === 'boolean' ? (val ? 'Yes' : 'No') : val;
    };

    return (
        <SkipNavContent>
            <main role="main" className="steward-display">
                {notFound && (
                    <>
                        <Alert alert={facilityNotFound} />
                        {buttons}
                    </>
                )}
                {!notFound && (
                    <>
                        {buttons}
                        <LoadingWrapper loading={loading} size="lg">
                            <div className="display-body">
                                {displayFields.map(
                                    ({
                                        field,
                                        label,
                                        arrayFields,
                                        arrayIdField,
                                        fullWidth,
                                    }) => (
                                        <TextDisplay
                                            key={field}
                                            label={label}
                                            field={field}
                                            arrayFields={arrayFields}
                                            arrayIdField={arrayIdField}
                                            fullWidth={fullWidth}
                                            value={getValue(field)}
                                        />
                                    )
                                )}
                            </div>
                        </LoadingWrapper>
                        {buttons}
                    </>
                )}
            </main>
        </SkipNavContent>
    );
}
