/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { useTable, useSortBy } from 'react-table';
import classnames from 'classnames';
import { Icon } from 'sarsaparilla';

import '../scss/components/_ReactTable.scss';

function ReactTable(props) {
    const {
        className = '',
        data,
        columns,
        loading = false,
        getTdProps = () => {},
        trClassName = '',
        noDataText = '',
        defaultSortId = 'id',
    } = props;

    const defaultColumn = {
        width: 50,
        minWidth: 30,
        maxWidth: 400,
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: defaultSortId
                ? { sortBy: [{ id: defaultSortId, desc: false }] }
                : undefined,
        },
        useSortBy
    );

    return (
        <div className={classnames(className, 'ReactTable')}>
            <div className="rt-table" {...getTableProps}>
                <div className="rt-thead -header">
                    {headerGroups.map((headerGroup, idx) => (
                        <div
                            key={idx}
                            {...headerGroup.getHeaderGroupProps()}
                            className="rt-tr"
                        >
                            {headerGroup.headers.map((header, index) => (
                                <div
                                    key={index}
                                    className="rt-th"
                                    {...header.getHeaderProps(
                                        header.getSortByToggleProps()
                                    )}
                                >
                                    {header.render('Header')}
                                    <span className="sort-indicator">
                                        {header.isSorted &&
                                            (header.isSortedDesc ? (
                                                <Icon iconName="arrow-up" />
                                            ) : (
                                                <Icon iconName="arrow-down" />
                                            ))}
                                    </span>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="rt-tbody">
                    <div {...getTableBodyProps()} className="rt-tr-group">
                        {rows.map((row: any, ind) => {
                            prepareRow(row);
                            return (
                                <React.Fragment key={row.id}>
                                    <div
                                        {...row.getRowProps()}
                                        className={classnames(
                                            'rt-tr',
                                            ind % 2 === 0 ? '-odd' : '-even',
                                            trClassName
                                        )}
                                    >
                                        {row.cells.map((cell, idx) => {
                                            return (
                                                <div
                                                    key={idx}
                                                    {...getTdProps(cell)}
                                                    {...cell.getCellProps()}
                                                    className={classnames(
                                                        'rt-td',
                                                        cell.column.className
                                                    )}
                                                >
                                                    {cell.render('Cell')}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </React.Fragment>
                            );
                        })}
                        {rows.length < 1 && (
                            <div className="rt-tr no-data">
                                <div className="rt-noData">{noDataText}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={classnames('-loading', loading ? '-active' : '')}>
                <div className="-loading-inner">Loading...</div>
            </div>
        </div>
    );
}

export default ReactTable;
