/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import moment from 'moment';

export const decodeEventKeys = {
    EventID: 'id',
    EntityID: 'entityId',
    EntityType: 'entityType',
    EventName: 'name',
    Description: 'description',
    EventTypeDescription: 'typeDescription',
    EventFeeDescription: 'feeDescription',
    EventFrequencyRateDescription: 'frequencyRateDescription',
    EventScopeDescription: 'scopeDescription',
    EventAgeGroup: 'ageGroup',
    EventRegistrationRequired: 'registrationRequired',
    EventADAAccess: 'adaAccess',
    EventComments: 'comments',
    EventEmail: 'email',
    EventURLAddress: 'url',
    EventURLText: 'urlText',
    EventStartDate: 'startDate',
    EventEndDate: 'endDate',
    SponsorName: 'sponsorName',
    SponsorClassType: 'sponsorClassType',
    SponsorPhone: 'sponsorPhone',
    SponsorEmail: 'sponsorEmail',
    SponsorURLAddress: 'sponsorUrl',
    SponsorURLText: 'sponsorUrlText',
};

export const encodeEventKeys = {
    id: 'eventID',
    entityId: 'entityID',
    entityType: 'entityType',
    name: 'eventName',
    description: 'eventDescription',
    typeDescription: 'eventTypeDescription',
    feeDescription: 'eventFeeDescription',
    frequencyRateDescription: 'eventFrequencyRateDescription',
    scopeDescription: 'eventScopeDescription',
    ageGroup: 'eventAgeGroup',
    registrationRequired: 'eventRegistrationRequired',
    adaAccess: 'eventADAAccess',
    comments: 'eventComments',
    email: 'eventEmail',
    url: 'eventURLAddress',
    urlText: 'eventURLText',
    startDate: 'eventStartDate',
    endDate: 'eventEndDate',
    sponsorName: 'sponsorName',
    sponsorClassType: 'sponsorClassType',
    sponsorPhone: 'sponsorPhone',
    sponsorEmail: 'sponsorEmail',
    sponsorUrl: 'sponsorURLAddress',
    sponsorUrlText: 'sponsorURLText',
};

export const eventDefaults = {
    id: '',
    entityId: '',
    entityType: '',
    name: '',
    description: '',
    typeDescription: '',
    feeDescription: '',
    frequencyRateDescription: '',
    scopeDescription: '',
    ageGroup: '',
    registrationRequired: false,
    adaAccess: '',
    comments: '',
    email: '',
    url: '',
    urlText: '',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    sponsorName: '',
    sponsorClassType: '',
    sponsorPhone: '',
    sponsorEmail: '',
    sponsorUrl: '',
    sponsorUrlText: '',
};
