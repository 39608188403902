/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Translator } from './translator';
import { Globals } from '../../globals';
import RecArea from './recArea';
import Facility from './facility';
import Organization from './organization';
import Link from './link';
import Media from './media';
import { IAssetDecodeRecord } from '../../types/asset/asset';
import AnyObject = Globals.AnyObject;
import mergeDefaults = Globals.mergeDefaults;

class Asset extends Translator {
    decode = (record: IAssetDecodeRecord) => {
        const originalAsset = record.hasOwnProperty('RecAreaID')
            ? RecArea.decode(record)
            : Facility.decode(record);

        const newRecord = mergeDefaults(record, {
            ORGANIZATION: [],
            LINK: [],
            MEDIA: [],
        });

        return {
            ...originalAsset,
            eventsToLoad: newRecord.EVENT && newRecord.EVENT.length > 0,
            organizations: newRecord.ORGANIZATION.map(Organization.decode),
            links: newRecord.LINK.map(Link.decode),
            media: newRecord.MEDIA.map(Media.decode),
        };
    };

    encode = (record: AnyObject) => {
        return void 0;
    };
}

export default new Asset({ main: void 0 });
