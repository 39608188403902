/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { emailField } from '../shared/utils/asset';
import {
    USER_SEARCH_ADD_ERROR,
    USER_SEARCH_CLEAR_ERROR,
    USER_SEARCH_UPDATE,
} from '../shared/constants/actions';

export const updateUserSearchTerm = (term) => (dispatch) =>
    dispatch({ type: USER_SEARCH_UPDATE, payload: term });

export const userSearchAddError = (error) => (dispatch) =>
    dispatch({ type: USER_SEARCH_ADD_ERROR, payload: error });
export const userSearchClearErrors = () => (dispatch) =>
    dispatch({ type: USER_SEARCH_CLEAR_ERROR });

export const saveUserSearchTerm = (term) => (dispatch) => {
    dispatch(userSearchClearErrors());
    dispatch(updateUserSearchTerm(term));
    const errors = emailField(term);
    if (errors && errors.length > 0) {
        dispatch(userSearchAddError(errors));
        return false;
    }
    return true;
};
