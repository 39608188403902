/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { ILink } from '../../types/asset/links';

export const decodeLinkKeys = {
    EntityLinkID: 'id',
    LinkType: 'type',
    EntityID: 'entityId',
    EntityType: 'entityType',
    Title: 'title',
    Description: 'description',
    URL: 'url',
};

export const encodeLinkKeys = {
    id: 'entityLinkID',
    type: 'linkType',
    title: 'title',
    description: 'description',
    url: 'URL',
};

export const linkDefaults: ILink = {
    id: null,
    entityId: null,
    entityType: null,
    type: '',
    title: '',
    description: '',
    url: '',
};

export const linkTypeOptions = [
    { label: 'Select Type', value: '' },
    { label: 'Concessionaires', value: 'Concessionaires' },
    { label: 'Equipment Rental', value: 'Equipment Rental' },
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Flickr', value: 'Flickr' },
    { label: 'Google+', value: 'Google+' },
    { label: 'Local Partnerships', value: 'Local Partnerships' },
    { label: 'Map', value: 'Map' },
    { label: 'Official Web Site', value: 'Official Web Site' },
    { label: 'Reservation', value: 'Reservation' },
    { label: 'Twitter', value: 'Twitter' },
    { label: 'Virtual Tours', value: 'Virtual Tours' },
    { label: 'YouTube Channel', value: 'YouTube Channel' },
    { label: 'Other', value: 'Other' },
];
