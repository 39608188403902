/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

const flatOrg = (current, org) => {
    const { children } = org;
    current.push(org);
    return children ? children.reduce(flatOrg, current) : current;
};

export const flattenOrgs = (orgs) => orgs.reduce(flatOrg, []);

export const flattenOrg = (org) => flatOrg([], org);

export const orgsToOptions = (orgs) => {
    return orgs
        .filter((org) => org.id !== '')
        .map((org) => ({
            label: `${'> '.repeat(Math.max(0, (org.path || 0).length - 1))}${org.name}`,
            value: org.id || '',
            org,
        }));
};
