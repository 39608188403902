/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component } from 'react';
import { Button, ButtonGroup, StyledModal, ModalActions } from 'sarsaparilla';

import { Globals } from '../../shared/globals';
import AnyFunction = Globals.AnyFunction;

type ClickwrapModalProps = {
    shouldPresent?: boolean;
    onCancel: AnyFunction;
    onContinue: AnyFunction;
};

const defaultProps = {
    shouldPresent: false,
    onCancel: () => void 0,
    onContinue: () => void 0,
};

class ClickWrapModal extends Component<ClickwrapModalProps> {
    modalRef = React.createRef();

    constructor(props = defaultProps) {
        super(props);
    }

    render() {
        const { onCancel, onContinue, shouldPresent } = this.props;
        const CONTINUE_TEXT = 'Yes';
        const CANCEL_TEXT = 'Cancel';
        return (
            <div>
                <StyledModal
                    ref={this.modalRef}
                    isOpen={shouldPresent}
                    onRequestClose={onCancel}
                    role="complementary"
                    contentLabel="Review"
                    heading="Agreement"
                    ariaHideApp={false}
                >
                    <div className="rec-modal-content-wrap">
                        <h4 className="rec-modal-title">Review</h4>
                        <p>
                            <strong>
                                Are you sure you want to publish this information to
                                Camping?
                            </strong>
                        </p>
                    </div>

                    <ModalActions>
                        <ButtonGroup isStretchedToFit>
                            <Button
                                appearance="tertiary"
                                title={CANCEL_TEXT}
                                aria-label={CANCEL_TEXT}
                                onClick={onCancel}
                            >
                                {CANCEL_TEXT}
                            </Button>
                            <Button
                                title={CONTINUE_TEXT}
                                aria-label={CONTINUE_TEXT}
                                onClick={onContinue}
                            >
                                {CONTINUE_TEXT}
                            </Button>
                        </ButtonGroup>
                    </ModalActions>
                </StyledModal>
            </div>
        );
    }
}

export default ClickWrapModal;
