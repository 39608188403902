/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { TextField } from 'sarsaparilla';
import {
    getCssClassesFromProps,
    getIdFromProps,
    getLabelFromProps,
    IWrappedInputsBaseProps,
} from './wrappedInputs';
import '../../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';

interface NumberInputProps extends IWrappedInputsBaseProps {
    required?: boolean;
    value?: any;
    fullWidth?: boolean;
}

function AssetEditNumberInput(props: NumberInputProps) {
    const {
        id: propsId = null,
        value = '',
        field,
        label: propsLabel,
        fullWidth,
        onChange,
        required,
    } = props;
    const id = getIdFromProps(propsId, field, 'number-');
    const label = getLabelFromProps(propsLabel, id);
    const css = getCssClassesFromProps(required, fullWidth);

    return (
        <div className={css}>
            {label}
            <TextField
                label=""
                id={id}
                data-field={field}
                value={value}
                onChange={onChange}
            />
        </div>
    );
}

export default AssetEditNumberInput;
