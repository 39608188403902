/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { makeAsyncAction } from '../shared/utils/async';
import { FETCH_ACTIVITIES } from '../shared/constants/actions';
import API from '../shared/api';

export const fetchActivities = makeAsyncAction(
    FETCH_ACTIVITIES,
    (query) => () => API.fetchActivities(query),
    'data'
);
