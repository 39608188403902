/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import axios, { AxiosResponse } from 'axios';
import Auth from './services/auth';
import { keysToSnakeCase } from './utils/object';
import { Globals } from './globals';
import { IChangeLogResponse } from './types/changeLog';
import cache from './services/cache';
import { typeToAsset } from './utils/asset';
import { IApiKey } from './types/auth/apiKey';
import { IMachineApiKey } from './types/auth/machineApiKey';
import AnyFunction = Globals.AnyFunction;
import Headers = Globals.Headers;
import isLocalEnvironment = Globals.isLocalEnvironment;

type AxiosOptions = { headers: Headers };
type APIResponse<R = any> = Promise<AxiosResponse<R>>;
type APIOperations = 'get' | 'post' | 'put' | 'delete' | 'patch';

const defaultOptions = { headers: {} };
const ACCOUNTS_API_ROOT = `${process.env.RIDB_API}/accounts`;
const DATA_API_ROOT = `${process.env.RIDB_API}/v1/data`;
const PORTAL_API_ROOT = `${process.env.RIDB_API}/developer-portal`;
const RIDB_API_ROOT = `${process.env.RIDB_API}/v1`;
const RIDB_PUBLIC_API_ROOT = `${process.env.RIDB_API}/v1/public`;

export type APIVerbs = 'PUT' | 'POST' | 'PATCH' | 'GET' | 'DELETE' | 'OPTIONS';

export enum ResponseCodes {
    BAD_REQUEST = 400,
    UNAUTHORIZED,
    SERVER_ERROR = 500,
}

export const endpoints = {
    login: () => `${ACCOUNTS_API_ROOT}/login`,
    keys: () => `${PORTAL_API_ROOT}/api_keys`,
    keyById: (id: string) => `${PORTAL_API_ROOT}/api_keys/${id}`,
    disableMachineKeyById: (id: string) =>
        `${PORTAL_API_ROOT}/machine_api_keys/disable/${id}`,
    machineKeyById: (id: string) => `${PORTAL_API_ROOT}/machine_api_keys/${id}`,
    machineKeys: () => `${PORTAL_API_ROOT}/machine_api_keys`,
    publicOrgs: () => `${RIDB_PUBLIC_API_ROOT}/organizations`,
    publicAssets: () => `${RIDB_PUBLIC_API_ROOT}/assets`,
    publicActivities: () => `${RIDB_PUBLIC_API_ROOT}/activities`,
    assetApi: (type: string, id: number) => `${RIDB_API_ROOT}/${typeToAsset(type)}/${id}`,
    account: () => `${PORTAL_API_ROOT}/internal/accounts`,
    eventApi: (assetType: string, assetId: number, eventId?: number) => {
        const idComponent = eventId ? `/${eventId}` : '';
        return `${RIDB_API_ROOT}/${typeToAsset(
            assetType
        )}/${assetId}/events${idComponent}`;
    },
    previewApi: (assetType: string, assetId: number) => {
        return `${DATA_API_ROOT}/${typeToAsset(assetType)}/publish/${assetId}/preview`;
    },
    publishApi: (assetType: string, assetId: number) => {
        return `${DATA_API_ROOT}/${typeToAsset(assetType)}/publish/${assetId}`;
    },
    changeLogItems: () => `${PORTAL_API_ROOT}/changelog`,
    accountById: (id: number) =>
        `${PORTAL_API_ROOT}/internal/accounts/${encodeURIComponent(id)}`,
    ridbRoot: (url: string) => `${RIDB_API_ROOT}${url}`,
};

export const ridbAPIBase: { [key in APIOperations]?: (...params: any) => APIResponse } = {
    get: (url: string, options: AxiosOptions = defaultOptions) => axios.get(url, options),
    post: (url: string, body = {}, options: AxiosOptions = defaultOptions) =>
        axios.post(url, body, options),
    put: (url: string, body = {}, options: AxiosOptions = defaultOptions) =>
        axios.put(url, body, options),
    delete: (url: string, options: AxiosOptions = defaultOptions) =>
        axios.delete(url, options),
};

export function createAPIHelper(authHeaders: (headers: Headers) => Headers) {
    return {
        ...ridbAPIBase,
        auth: (url: string, options: any = defaultOptions) => ({
            get: <Response = any>(): APIResponse<Response> =>
                ridbAPIBase.get(url, {
                    ...options,
                    headers: authHeaders(options.headers),
                }),
            post: <Response = any>(data?: any): APIResponse<Response> =>
                ridbAPIBase.post(url, data, {
                    ...options,
                    headers: authHeaders(options.headers),
                }),
            put: <Response = any>(data?: any): APIResponse<Response> =>
                ridbAPIBase.put(url, data, {
                    ...options,
                    headers: authHeaders(options.headers),
                }),
            delete: <Response = any>(): APIResponse<Response> =>
                ridbAPIBase.delete(url, {
                    ...options,
                    headers: authHeaders(options.headers),
                }),
        }),
    };
}

export const apiHelper = createAPIHelper(Auth.getHeaders);

export const API = {
    generateApiKey: () => apiHelper.auth(endpoints.keyById('generate')).post(),
    generateMachineKeys: () =>
        apiHelper.auth(endpoints.machineKeyById('generate')).post(),
    fetchMachineKeys: (): APIResponse<[IMachineApiKey]> =>
        apiHelper.auth(endpoints.machineKeys()).get(),
    disableMachineKey: (id: string) =>
        apiHelper.auth(endpoints.disableMachineKeyById(id)).delete(),
    fetchOrganizations: (p?: any) =>
        cache.handle(endpoints.publicOrgs(), () =>
            apiHelper.auth(endpoints.publicOrgs(), { params: p }).get()
        ),
    fetchChangeLogItems: (): APIResponse<IChangeLogResponse> =>
        apiHelper.auth(endpoints.changeLogItems()).get(),
    fetchAssets: (params?: any) =>
        apiHelper.auth(endpoints.publicAssets(), { params }).get(),
    fetchActivities: (params?: any) =>
        cache.handle(endpoints.publicActivities(), () =>
            apiHelper.auth(endpoints.publicActivities(), { params }).get()
        ),
    fetchAsset: (type: string, id: number, params: any) =>
        apiHelper.auth(endpoints.assetApi(type, id), { params }).get(),
    fetchEvents: (assetType: string, assetId: number, params: any) =>
        apiHelper.auth(endpoints.eventApi(assetType, assetId), { params }).get(),
    fetchPreview: (assetType: string, assetId: number) =>
        apiHelper.auth(endpoints.previewApi(assetType, assetId)).get(),
    publishPreview: (assetType: string, assetId: number) =>
        apiHelper.auth(endpoints.publishApi(assetType, assetId)).post(),
    createResource: (url: string, data: any, options?: object) =>
        apiHelper.auth(endpoints.ridbRoot(url), options).post(data),
    updateResource: (url: string, data: any, options?: object) =>
        apiHelper.auth(endpoints.ridbRoot(url), options).put(data),
    deleteResource: (url: string, data?: any) =>
        apiHelper.auth(endpoints.ridbRoot(url)).delete(),
    getAccount: (params: any) => apiHelper.auth(endpoints.account(), { params }).get(),
    enableDeveloperAccess: (accountId: number) =>
        apiHelper.auth(endpoints.accountById(accountId), {}).post(),
    updateAccount: (details: Partial<{ externalId: number }>) =>
        apiHelper
            .auth(endpoints.accountById(details.externalId))
            .put(keysToSnakeCase(details)),
};

export const apiResourceFromVerb: { [key in APIVerbs]?: AnyFunction<APIResponse> } = {
    POST: API.createResource,
    PUT: API.updateResource,
    DELETE: API.deleteResource,
};

export default API;
