/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    SAVE_PROGRESS_START,
    SAVE_PROGRESS_STEP,
    SAVE_PROGRESS_CLEAR,
    SAVE_ADD_ERROR,
    SAVE_CLEAR_ERRORS,
} from '../shared/constants/actions';

export const saveStatusInitialState = {
    total: 0,
    complete: 0,
    errors: [],
};

export default (state = saveStatusInitialState, { type, payload }): any => {
    switch (type) {
        case SAVE_PROGRESS_START:
            return { ...state, total: payload, complete: 0 };
        case SAVE_PROGRESS_STEP:
            return { ...state, complete: state.complete + 1 };
        case SAVE_PROGRESS_CLEAR:
            return { ...state, total: 0, complete: 0 };
        case SAVE_ADD_ERROR:
            // console.log({errors: [...state.errors], payload});
            const errors = [...state.errors].filter(
                (v, i, a) =>
                    a.findIndex(
                        (t) => t.description === v.description && t.error === v.error
                    ) === i
            );
            console.log(errors);
            if (
                errors.filter(
                    ({ description, error }) =>
                        description === payload.description && error === payload.error
                ).length === 0
            ) {
                errors.push(payload);
            }
            return { ...state, errors };
        case SAVE_CLEAR_ERRORS:
            return { ...state, errors: [] };
        default:
            return state;
    }
};

export const getSaveStatus = ({ portal: { saveStatus } }) => saveStatus;
