/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { combineReducers } from 'redux';
import { makeAsyncSelectors } from '../shared/utils/async';
import { alerts, selectors as alertsSel } from './alerts';

import activities from './activities';
import organizations from './organizations';
import search from './search';
import accounts from './accounts';
import changeLog from './changeLog';
import assets from './assets';
import currentUser from './currentUser';
import changes from './changes';
import saveStatus from './saveStatus';
import events from './events';
import asset from './asset';
import assetsForAutoComplete from './assetsForAutoComplete';
import apiKey from './apiKey';
import apiKeys from './apiKeys';
import userSearch from './userSearch';
import preview from './preview';
import publish from './publish';

const { isLoading, isLoaded, getState, getErrors, hasErrors } =
    makeAsyncSelectors('portal');

export default combineReducers({
    assets,
    accounts,
    currentUser,
    activities,
    alerts,
    changes,
    saveStatus,
    events,
    assetsForAutoComplete,
    asset,
    organizations,
    search,
    changeLog,
    apiKey,
    apiKeys,
    userSearch,
    preview,
    publish,
});

export { alertsSel, isLoading, isLoaded, getState, getErrors, hasErrors };
