/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { get } from 'lodash';
import { Globals } from '../globals';
import Auth, { AuthTargetedTypes } from '../services/auth';
import mergeDefaults = Globals.mergeDefaults;

export enum StateStatus {
    UNKNOWN = -1,
    UNLOADED,
    LOADING,
    READY,
    ERROR,
}

interface ActionParams {
    type: any;
    key: string;
    getState: (...params: any) => any;
}

interface SuccessActionParams extends ActionParams {
    payload: any;
}

interface ErrorActionParams extends ActionParams {
    error: Error;
    message: string;
}

export type ActionObject = {
    start?: (dispatch: any, params: ActionParams) => any;
    success: (dispatch: any, params: SuccessActionParams) => any;
    failure?: (dispatch: any, params: ErrorActionParams) => any;
};

export function asyncDefaults(data: any) {
    return {
        loading: false,
        loaded: false,
        error: void 0,
        data,
    };
}

// Default action step functions
// Function signature (dispatch, { getState, type, key }) => { return <action> }
const startActionFn = (dispatch, params) => dispatch({ type: params.type });
// Function signature (dispatch, { getState, payload, type, key }) => { return <action> }
const successActionFn = (dispatch, { payload, type }) => dispatch({ type, payload });
// Function signature (dispatch, { getState, error, message, type, key }) => { return <action> }
const failureActionFn = (dispatch, { message, type }) =>
    dispatch({ type, error: message });

const defaultActionObject = {
    start: startActionFn,
    success: successActionFn,
    failure: failureActionFn,
};

export function makeAsyncAction(
    type?: string,
    thunkCallback?: (...params: any) => any,
    key?: string,
    actionObject: ActionObject = defaultActionObject
) {
    return (...args) => {
        const thunk = thunkCallback(...args);
        const { start, success, failure } = mergeDefaults(
            actionObject,
            defaultActionObject
        );

        return async (dispatch, getState) => {
            start(dispatch, { getState, type: `${type}_START`, key });

            try {
                const response = await thunk();
                const payload =
                    key !== undefined ? get(response, `data.${key}`) : response.data;
                if (payload === undefined) {
                    throw new Error(`Did not receive data from the server.`);
                }
                if (payload === 'api_keys') {
                    Auth.target(AuthTargetedTypes.API_KEYS, payload);
                }

                success(dispatch, { getState, payload, type: `${type}_SUCCESS`, key });

                return payload;
            } catch (error: any) {
                const { response, request, message: errorMessage } = error;
                let message = errorMessage || 'Something went wrong unexpectedly';

                if (response) {
                    // Handle 5xx errors
                    const resp = error.response;
                    const { data, status } = resp;
                    if (data && data.err) {
                        message = data.err;
                    } else if (status >= 500) {
                        switch (status) {
                            case 500: // Internal Server Error
                                message =
                                    'The server encountered an error processing the request. Try again later.';
                                break;
                            case 501: // Not Implemented
                                message =
                                    'The server does not know how to respond to that request.';
                                break;
                            case 502: // Bad Gateway
                            case 503: // Service Unavailable
                            case 504: // Gateway Timeout
                                message =
                                    'The server could not be reached or is not responding. Try again later.';
                                break;
                            default:
                                message =
                                    'Something went wrong while talking with the server. Try again later.';
                        }
                    } else if (status >= 400) {
                        switch (status) {
                            case 401: // Unauthorized (not authenticated)
                                message = 'You must be logged in to do that action.';
                                break;
                            case 403: // Forbidden (not authorized)
                                message =
                                    'Your permissions do not permit you to do that action.';
                                break;
                            case 404: // Not Found
                                message = 'No data for that resource was found.';
                                break;
                            case 422: // Unprocessable Entity
                                message =
                                    'There are errors in your request, check your inputs and try again.';
                                break;
                            default:
                                message =
                                    'A problem with your request has occurred. Try again later.';
                        }
                    } else {
                        message =
                            'A temporary problem has caused your request to fail. Try again later.';
                    }
                }
                // Error with the request (no server response)
                else if (request) {
                    message = 'Something went wrong while talking to the server';
                }

                failure(dispatch, {
                    getState,
                    error,
                    message,
                    type: `${type}_FAILURE`,
                    key,
                });
                throw new Error(message);
            }
        };
    };
}

export function mergeReducers(initialState, prefix, ...reducers) {
    return (initial = initialState, action) => {
        if (typeof action.type === 'undefined' || !action.type.startsWith(prefix)) {
            return initial;
        }

        return reducers.reduce((state, reducer) => reducer(state, action), initial);
    };
}

export function makeAsyncResetAction(type) {
    return () => (dispatch) => dispatch({ type });
}

const startReducerFn = (state) => ({
    loading: true,
    loaded: false,
    error: null,
    data: state.data,
});
const successReducerFn = (state, action) => ({
    loading: false,
    loaded: true,
    error: null,
    data: action.payload,
});
const failureReducerFn = (state, action) => ({
    loading: false,
    loaded: true,
    error: action.error,
    data: state.data,
});

export const defaultReducerObject = {
    start: startReducerFn,
    success: successReducerFn,
    failure: failureReducerFn,
};

export function makeAsyncReducer(
    type: string,
    initialData = void 0,
    reducerObject = defaultReducerObject
) {
    const reducer = mergeDefaults(reducerObject, defaultReducerObject);
    const initial = { loading: false, loaded: false, error: null, data: initialData };
    const { start, success, failure } = reducer;

    return (state = initial, action) => {
        switch (action.type) {
            case `${type}_START`:
                return start(state);
            case `${type}_SUCCESS`:
                return success(state, action);
            case `${type}_FAILURE`:
                return failure(state, action);
            default:
                return state;
        }
    };
}

type AsyncSelectorResultType = {
    all?: any;
    any?: any;

    [key: string]: any;
};

export const getDefaultSelector =
    (reducer: string, field?: string, nullCheck = false) =>
    (state, ...keys): AsyncSelectorResultType => {
        const result: AsyncSelectorResultType = {};
        let all = true;
        let any = false;
        const exp = (key) =>
            state[reducer] &&
            state[reducer][key] &&
            state[reducer][key][field] &&
            (nullCheck ? state[reducer][key][field] !== null : true);

        keys.forEach((key) => {
            all = all && exp(key);
            any = any || exp(key);
            result[key] = exp(key);
        });

        return { ...result, any, all };
    };

/**
 * Makes some async selector helpers for $reducer
 *
 * isLoading() Returns an object of booleans. Any and All are included for quick checks across all keys
 * isLoaded() Returns an object of booleans. Any and All are included for quick checks across all keys
 * hasErrors() Returns an object of booleans. Any and All are included for quick checks across all keys
 * getErrors() Returns an object of error messages for only the keys that have errors.
 *
 * @param {string} reducer - name of UI reducer found on the root state object
 * @return {Object} object of selector functions
 */

export function makeAsyncSelectors(reducer) {
    return {
        isLoading: getDefaultSelector(reducer, 'loading'),
        isLoaded: getDefaultSelector(reducer, 'loaded'),
        getState: (state, ...keys): AsyncSelectorResultType => {
            const result: AsyncSelectorResultType = {};

            keys.forEach((key) => {
                if (!state[reducer] || !state[reducer][key]) {
                    result[key] = StateStatus.UNKNOWN;
                } else {
                    const value = state[reducer][key];
                    if (value.error) {
                        result[key] = StateStatus.ERROR;
                    } else if (value.loading) {
                        result[key] = StateStatus.LOADING;
                    } else if (value.loaded) {
                        result[key] = StateStatus.READY;
                    } else {
                        result[key] = StateStatus.UNLOADED;
                    }
                }
            });

            return result;
        },
        hasErrors: getDefaultSelector(reducer, 'error', true),
        getErrors: (state, ...keys): AsyncSelectorResultType => {
            const result: AsyncSelectorResultType = {};
            keys.forEach((key) => {
                if (state[reducer][key].error !== null) {
                    result[key] =
                        state[reducer] &&
                        state[reducer][key] &&
                        state[reducer][key].error;
                }
            });
            return result;
        },
    };
}

/**
 * Creates a simple reducer to reset the async helper state back to initial data
 * @param {string} type - action type
 * @param {*} [initialData=null]
 * @return {function(state, action): *} redux reducer function
 */
export function makeAsyncResetReducer(type, initialData = null) {
    return (state, action) => (action.type === type ? asyncDefaults(initialData) : state);
}
