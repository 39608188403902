/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Route, Navigate } from 'react-router-dom-v5-compat';
import { NotFoundPage } from 'sarsaparilla';

import { routes } from './routes';
import LandingPage from './containers/LandingPage';
import DownloadPage from './containers/DownloadPage';
import AccessAgreementPage from './containers/AccessAgreementPage';
import LoginPage from './containers/LoginPage';
import ProfilePage from './containers/ProfilePage/ProfilePage';
import PublishToCampingPage from './containers/PublishToCampingPage/PublishToCampingPage';
import MachineTokensPage from './containers/MachineTokensPage/MachineTokensPage';
import { RequireAuthRidb } from './shared/components/RequireAuthRidb';
import DocumentationPage from './containers/DocumentationPage';
import ChangeLogPage from './containers/ChangeLogPage';
import SearchPage from './containers/SearchPage';
import StandardsPage from './containers/StandardsPage';
import StewardAssetEntryPage from './containers/StewardAsset/StewardAssetEntryPage';
import StewardAssetDisplayPage from './containers/StewardAsset/StewardAssetDisplayPage';
import StewardAssetEditPage from './containers/StewardAsset/StewardAssetEditPage/StewardAssetEditPage';
import StewardAssetSearchPage from './containers/StewardAsset/StewardAssetSearchPage';

import StewardAssetNewPage from './containers/StewardAsset/StewardAssetNewPage';
import { Globals } from './shared/globals';
import configurePath = Globals.configurePath;
import UserAdminPage from './containers/UserAdminPage/UserAdminPage';

const components = {
    LandingPage,
    DownloadPage,
    AccessAgreementPage,
    LoginPage,
    ProfilePage,
    PublishToCampingPage,
    MachineTokensPage,
    DocumentationPage,
    ChangeLogPage,
    SearchPage,
    StandardsPage,
    StewardAssetEntryPage,
    StewardAssetDisplayPage,
    StewardAssetEditPage,
    StewardAssetSearchPage,
    StewardAssetNewPage,
    UserAdminPage,
};

export function makeSwitchRoutes() {
    const routeBase = process.env['ROUTE_BASE'] || '';
    return routes
        .map((route) => {
            const {
                componentName,
                requiresAdminAccess,
                requiresWriteAccess,
                exact = false,
                path: rawPath,
                requiresAuth,
            } = route;

            const path = configurePath(rawPath);

            //@ts-ignore
            const Component = components[componentName];

            if (requiresAdminAccess || requiresWriteAccess || requiresAuth) {
                return (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <RequireAuthRidb
                                requiresAdminAccess={requiresAdminAccess}
                                requiresWriteAccess={requiresWriteAccess}
                            >
                                <Component />
                            </RequireAuthRidb>
                        }
                    />
                );
            }
            return <Route key={path} path={path} element={<Component />} />;
        })
        .concat(
            <Route
                key="/landing-redirect-1"
                path={routeBase}
                element={<Navigate to={`${routeBase}/landing`} />}
            />,
            <Route
                key="/landing-redirect-2"
                path="/"
                element={<Navigate to={`${routeBase}/landing`} />}
            />,
            <Route path="*" key="notFound" element={<NotFoundPage />} />
        );
}
