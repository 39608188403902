/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { asyncDefaults, makeAsyncReducer, mergeReducers } from '../shared/utils/async';
import { FETCH_ACTIVITIES, RIDB_ACTION_PREFIX } from '../shared/constants/actions';

const fetchActivities = makeAsyncReducer(FETCH_ACTIVITIES);

export default mergeReducers(asyncDefaults([]), RIDB_ACTION_PREFIX, fetchActivities);

export const getActivities = (state) => state.portal.activities.data;
