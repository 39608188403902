/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

export const decodeOrganizationKeys = {
    OrgID: 'id',
    OrgName: 'name',
    OrgImageURL: 'imageUrl',
    OrgURLText: 'urlText',
    OrgURLAddress: 'urlAddress',
    OrgType: 'type',
    OrgAbbrevName: 'abbrevName',
    OrgJurisdictionType: 'jurisdictionType',
    OrgParentID: 'parentId',
    LastUpdatedDate: 'lastUpdatedDate',
};

export enum Organizations {
    FEDERAL = 157,
}
