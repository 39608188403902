/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { apiKeyDefaults } from '../shared/constants/auth';
import { asyncDefaults, makeAsyncReducer, mergeReducers } from '../shared/utils/async';
import {
    FETCH_APIKEY,
    GENERATE_APIKEY,
    FETCH_MACHINE_KEYS,
    GENERATE_MACHINE_KEYS,
    RIDB_ACTION_PREFIX,
} from '../shared/constants/actions';

const fetchApiKey = makeAsyncReducer(FETCH_APIKEY);
const generateApiKey = makeAsyncReducer(GENERATE_APIKEY);

const fetchMachineKeys = makeAsyncReducer(FETCH_MACHINE_KEYS);
const generateMachineKeys = makeAsyncReducer(GENERATE_MACHINE_KEYS);

export const getMachineKeys = (state) => state.portal.apiKeys?.data;

export default mergeReducers(
    asyncDefaults(apiKeyDefaults),
    RIDB_ACTION_PREFIX,
    fetchApiKey,
    generateApiKey,
    fetchMachineKeys,
    generateMachineKeys
);
