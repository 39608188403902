/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { makeAsyncAction } from '../shared/utils/async';
import { PUBLISH_PREVIEW } from '../shared/constants/actions';
import API from '../shared/api';

export const publishPreview = makeAsyncAction(
    PUBLISH_PREVIEW,
    (type, id) => () => API.publishPreview(type, id)
);
