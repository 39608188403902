/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { Button, Icon, FlexRow, FlexCol } from 'sarsaparilla';
import { IEvent } from '../../../../shared/types/asset/event';
import { Globals } from '../../../../shared/globals';
import AnyFunction = Globals.AnyFunction;
import {
    IInputPickerInput,
    InputPicker,
    InputPickerTypes,
} from '../../../../components/StewardAsset/StewardAssetEdit/InputPicker';
import { addChange, clearChange, getRef } from '../../../../actions/changes';
import { reduceAndRestructure } from '../../../../shared/utils/asset';
import TargetedEvent = Globals.TargetedEvent;
import '../../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';
import {
    isValidEmail,
    isValidPhoneNumber,
    isValidURL,
} from '../../../../shared/utils/string';
import { determineNewInvalidItems } from '../../../../shared/utils/validation';

const eventValidation = {
    email: (email: string) => email === '' || email === void 0 || isValidEmail(email),
    sponsorEmail: (email: string) =>
        email === '' || email === void 0 || isValidEmail(email),
    url: (url: string) => url === '' || url === void 0 || isValidURL(url),
    sponsorUrl: (url: string) => url === '' || url === void 0 || isValidURL(url),
    sponsorPhone: (phone) =>
        phone === '' || phone === void 0 || isValidPhoneNumber(phone),
};

const eventEditInputs: IInputPickerInput[] = [
    {
        type: InputPickerTypes.TEXT,
        label: 'Event Name',
        required: true,
        field: 'name',
    },
    {
        type: InputPickerTypes.TEXT,
        label: 'Event Email',
        field: 'email',
        isValid: (email) => eventValidation.email(email),
        errorText: 'Event Email is invalid',
    },
    {
        type: InputPickerTypes.TEXT,
        label: 'Event URL Address',
        field: 'url',
        isValid: (url) => eventValidation.url(url),
        errorText: 'Event URL Address is invalid',
    },
    {
        type: InputPickerTypes.TEXT,
        label: 'Event URL Text',
        field: 'urlText',
    },
    {
        type: InputPickerTypes.DATE,
        label: 'Event Start Date',
        field: 'startDate',
    },
    {
        type: InputPickerTypes.DATE,
        label: 'Event End Date',
        field: 'endDate',
    },
    {
        type: InputPickerTypes.TEXT,
        label: 'Event Age Group',
        field: 'ageGroup',
    },
    {
        type: InputPickerTypes.CHECKBOX,
        label: 'Registration Required',
        field: 'registrationRequired',
    },
    {
        type: InputPickerTypes.TEXTBOX,
        label: 'Event Description',
        field: 'description',
    },
    {
        type: InputPickerTypes.TEXTBOX,
        label: 'Event Type Description',
        field: 'typeDescription',
    },
    {
        type: InputPickerTypes.TEXTBOX,
        label: 'Event Scope Description',
        field: 'scopeDescription',
    },
    {
        type: InputPickerTypes.TEXTBOX,
        label: 'Event Frequency Rate Description',
        field: 'frequencyRateDescription',
    },
    {
        type: InputPickerTypes.TEXTBOX,
        label: 'Event Fee Description',
        field: 'feeDescription',
    },
    {
        type: InputPickerTypes.BLANK,
        field: 'blank1',
        label: void 0,
    },
    {
        type: InputPickerTypes.TEXT,
        label: 'Sponsor Name',
        field: 'sponsorName',
    },
    {
        type: InputPickerTypes.TEXT,
        label: 'Sponsor Class Type',
        field: 'sponsorClassType',
    },
    {
        type: InputPickerTypes.TEXT,
        label: 'Sponsor Phone',
        field: 'sponsorPhone',
        isValid: (phone) => eventValidation.sponsorPhone(phone),
        errorText: 'Event Sponsor Phone is invalid',
    },
    {
        type: InputPickerTypes.TEXT,
        label: 'Sponsor Email',
        field: 'sponsorEmail',
        isValid: (email) => eventValidation.sponsorEmail(email),
        errorText: 'Event Sponsor Email is invalid',
    },
    {
        type: InputPickerTypes.TEXT,
        label: 'Sponsor URL Address',
        field: 'sponsorUrl',
        isValid: (sponsorUrl) => eventValidation.sponsorUrl(sponsorUrl),
        errorText: 'Event Sponsor URL Address is invalid',
    },
    {
        type: InputPickerTypes.TEXT,
        label: 'Sponsor URL Text',
        field: 'sponsorUrlText',
    },
];

type EventEditProps = {
    value?: IEvent;
    eventType?: string;
    onRemove: AnyFunction;
    getRef: AnyFunction;
    addChange: AnyFunction;
    clearChange: AnyFunction;
    onChange: AnyFunction;
    onInvalidItemsChange?: AnyFunction;
};

type EventEditState = {
    record: any;
    ref: any;
    invalidItems: any[];
    deleted: boolean;
};

class EventEdit extends PureComponent<EventEditProps, EventEditState> {
    constructor(props) {
        super(props);

        const { value: record, getRef, eventType, addChange } = props;
        const paths = ['name'];
        const ref = getRef(
            eventType,
            { id: record.id },
            void 0,
            reduceAndRestructure(paths, record)
        );

        this.state = {
            ref,
            record,
            invalidItems: [],
            deleted: false,
        };

        if (!record.id) {
            addChange(ref, { registrationRequired: false });
            addChange(ref, { startDate: moment().format('YYYY-MM-DD') });
            addChange(ref, { endDate: moment().format('YYYY-MM-DD') });
        }
    }

    handleChange = (event: TargetedEvent, dateField = '') => {
        const { invalidItems } = this.state;
        const { onInvalidItemsChange } = this.props;
        let change;
        const field = event.target?.dataset?.field;
        const value = event.target?.value;
        if (!dateField) {
            change = { [field]: value };
        } else {
            change = { [dateField]: event };
        }
        const {
            state: { ref },
            props: { addChange, onChange },
        } = this;
        const newInvalids = determineNewInvalidItems(
            eventValidation,
            field,
            value,
            invalidItems,
            'Event'
        );
        if (newInvalids) {
            this.setState({ invalidItems: newInvalids }, () => {
                onInvalidItemsChange(newInvalids);
            });
        }
        this.setState(
            (prevState) => {
                const record = { ...prevState.record, ...change };
                onChange(record);
                return { ...prevState, record };
            },
            () => addChange(ref, change)
        );
    };

    doRemove = () => {
        const {
            value: { _key, id },
            addChange,
            clearChange,
            onRemove,
            onInvalidItemsChange,
        } = this.props;
        const { ref, invalidItems } = this.state;
        if (id) {
            addChange(ref, { _delete: true });
        } else {
            clearChange(ref);
        }
        onRemove(_key);
        this.setState({ deleted: true });
        const newInvalids = invalidItems.map((i) => ({ ...i, _delete: true }));
        this.setState({ invalidItems: newInvalids });
        onInvalidItemsChange(newInvalids);
    };

    render() {
        const { record, ref } = this.state;

        return (
            <div className="section-box">
                <Button
                    appearance="subtle-danger"
                    onClick={this.doRemove}
                    className="delete-button"
                    iconBeforeElement={<Icon iconName="close" />}
                />
                <FlexRow>
                    {eventEditInputs.map((input, index) => (
                        <FlexCol key={index} md={6}>
                            {input.type === InputPickerTypes.DATE
                                ? InputPicker(input, ref, record, (e) =>
                                      this.handleChange(e, input.field)
                                  )
                                : InputPicker(input, ref, record, this.handleChange)}
                        </FlexCol>
                    ))}
                </FlexRow>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getRef,
            addChange,
            clearChange,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(EventEdit);
