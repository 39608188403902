/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Icon, type IconName } from 'sarsaparilla';
import { Globals } from '../globals';
import AnyFunction = Globals.AnyFunction;
import { alertDefaults } from '../constants/alerts';
import mergeDefaults = Globals.mergeDefaults;
import AnyObject = Globals.AnyObject;
import '../../scss/components/_Alert.scss';

type AlertProps = {
    id: string;
    group?: string;
    level: string;
    title: string;
    message?: string;
    onClose?: AnyFunction;
    timeout?: number;
};

function Alert(props: { alert: AlertProps } | AnyObject = {}) {
    const {
        alert: { level, title, message, onClose },
    } = mergeDefaults(props, alertDefaults);
    const renderIcon = () => {
        let iconName: IconName;

        switch (level) {
            case 'info':
                iconName = 'info';
                break;
            case 'warning':
                iconName = 'warning';
                break;
            case 'success':
                iconName = 'check-circle';
                break;
            default:
                iconName = 'error';
        }

        return <Icon iconName={iconName} aria-label={title} />;
    };

    return (
        <div className={`rec-alert rec-notification-${level}`}>
            <span className="rec-notification-icon">{renderIcon()}</span>
            <div className="rec-notification-body">
                <div className="rec-notification-title">{title}</div>
                <p>{message}</p>
            </div>
            <button
                type="button"
                className="rec-notification-close"
                onClick={onClose}
                aria-label="Close alert"
            >
                <Icon iconName="close" />
            </button>
        </div>
    );
}

export default Alert;
