/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { asyncDefaults, makeAsyncReducer, mergeReducers } from '../shared/utils/async';
import { FETCH_CHANGE_LOG_ITEMS, RIDB_ACTION_PREFIX } from '../shared/constants/actions';

export const fetchChangeLog = makeAsyncReducer(FETCH_CHANGE_LOG_ITEMS);

export const getChangeLog = (state) => state.portal.changeLog.data;

export default mergeReducers(asyncDefaults([]), RIDB_ACTION_PREFIX, fetchChangeLog);
