/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Spinner } from 'sarsaparilla';
import { Globals } from '../globals';
import mergeDefaults = Globals.mergeDefaults;

const defaultProps = { loading: false, className: '' };

type LoadingWrapperProps = {
    loading: boolean;
    size: 'lg' | 'sm';
    className: string;
    children: any;
};

function LoadingWrapper(props: Partial<LoadingWrapperProps>) {
    const { className, loading, size, children } = mergeDefaults(props, defaultProps);

    if (loading) {
        return (
            <div className={`${className} loading-wrapper is-not-loaded`}>
                <Spinner size={size} />
            </div>
        );
    }

    return <div className={`${className} loading-wrapper is-loaded`}>{children}</div>;
}
export default LoadingWrapper;
