/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import uniqueId from 'lodash/uniqueId';
import SwaggerUIBundle from 'swagger-ui';
import { ICurrentUser } from '../shared/types/auth/currentUser';
import { useMountEffect } from '../shared/hooks/useMountEffect';

type DocumentationProps = {
    specs: string;
    currentUser: ICurrentUser;
};

function Documentation(props: DocumentationProps) {
    const docId = uniqueId('api-docs-');

    useMountEffect(() => {
        const { specs: url } = props;
        const swagger = SwaggerUIBundle({
            dom_id: `#${docId}`,
            url,
            deepLinking: true,
            defaultModelExpandDepth: 2,
            defaultModelsExpandDepth: 1,
            displayOperationId: true,
            supportedSubmitMethods: ['get'],
            requestInterceptor: (request) => {
                if (request.loadSpec) {
                    // Intercept the spec and replace the *_HOST with the correct one for the ENV we're running in.
                    request.responseInterceptor = (response) => {
                        const root = process.env.RIDB_ROOT;
                        const apiRoot = process.env.API_ROOT;
                        // RIDB_HOSTS
                        response.body.servers[0].url =
                            response.body.servers[0].url.replace('RIDB_HOST', root);
                        response.obj.servers[0].url = response.obj.servers[0].url.replace(
                            'RIDB_HOST',
                            root
                        );
                        response.data = response.data.replace('RIDB_HOST', root);
                        response.text = response.text.replace('RIDB_HOST', root);

                        // API_HOSTS
                        response.body.servers[0].url =
                            response.body.servers[0].url.replace('API_HOST', apiRoot);
                        response.obj.servers[0].url = response.obj.servers[0].url.replace(
                            'API_HOST',
                            apiRoot
                        );
                        response.data = response.data.replace('API_HOST', apiRoot);
                        response.text = response.text.replace('API_HOST', apiRoot);

                        return response;
                    };
                }

                return request;
            },
            onComplete: () => {
                // Automatically set the developers ApiKey
                setTimeout(() => {
                    const {
                        isDeveloper,
                        apiKey: { apiKey },
                    } = props.currentUser;
                    if (isDeveloper) {
                        swagger.preauthorizeApiKey('Apikey', apiKey);
                    }
                }, 10);
            },
        });
    });

    return (
        <div>
            <div id={docId} />
        </div>
    );
}

export default Documentation;
