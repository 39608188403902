/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    camelCase,
    cloneDeep,
    isArray,
    isPlainObject,
    mapKeys,
    mapValues,
    snakeCase,
} from 'lodash';

export function keysToCamelCase(object: object): object {
    if (!isPlainObject(object) && !isArray(object)) {
        return object;
    }
    let camelCaseObject = cloneDeep(object);
    if (isArray(camelCaseObject)) {
        return camelCaseObject.map(keysToCamelCase);
    }

    camelCaseObject = mapKeys(camelCaseObject, (value, key) => camelCase(key));

    return mapValues(camelCaseObject, (value: object) => {
        if (isPlainObject(value)) {
            return keysToCamelCase(value);
        }
        if (isArray(value)) {
            return value.map(keysToCamelCase);
        }
        return value;
    });
}

export function keysToSnakeCase(object: object): object {
    if (!isPlainObject(object) && !isArray(object)) {
        return object;
    }
    let snakeCaseObject = cloneDeep(object);
    if (isArray(snakeCaseObject)) {
        return snakeCaseObject.map(keysToSnakeCase);
    }
    snakeCaseObject = mapKeys(snakeCaseObject, (value, key) => snakeCase(key));
    return mapValues(snakeCaseObject, (value: any) => {
        if (isPlainObject(value)) {
            return keysToSnakeCase(value);
        }
        if (isArray(value)) {
            return value.map(keysToSnakeCase);
        }
        return value;
    });
}
