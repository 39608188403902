/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertsSel } from '../../reducers';
import Alert from '../components/Alert';
import { Globals } from '../globals';
import AnyFunction = Globals.AnyFunction;
import { clearAlerts } from '../../actions/alerts';
import mergeDefaults = Globals.mergeDefaults;
import { useMountEffect } from '../hooks/useMountEffect';
import '../../scss/components/_Alert.scss';

const defaultProps = {
    group: '',
};

type AlertsContainerProps = {
    group?: string;
    clearAlerts?: AnyFunction;
    alerts?: any[];
};

function AlertsContainer(props: AlertsContainerProps) {
    const { clearAlerts, group, alerts } = mergeDefaults<AlertsContainerProps>(
        props,
        defaultProps
    );

    useMountEffect(() => {
        return () => (clearAlerts ? void 0 : clearAlerts(group));
    });

    if (!alerts || alerts.length === 0) {
        return null;
    }

    return (
        <div className="rec-notification-alerts-container">
            {alerts.map((item) => (
                <Alert key={item.id} alert={item} />
            ))}
        </div>
    );
}

const mapStateToProps = (state, { group }) => ({
    alerts: alertsSel.getAlerts(state, group),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ clearAlerts }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AlertsContainer);
