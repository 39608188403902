/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    HelmetWrapper,
    SkipNavContent,
    TextField,
    TextArea,
    Select,
    SelectOption,
    Spinner,
    FlexCol,
    FlexRow,
    Spacer,
    TextBlock,
    Notification,
} from 'sarsaparilla';
import { getCurrentUser } from '../../reducers/currentUser';
import ClickWrapModal from './ClickWrapModal';
import { ICurrentUser } from '../../shared/types/auth/currentUser';
import { Globals } from '../../shared/globals';
import { fetchPreview } from '../../actions/preview';
import { publishPreview } from '../../actions/publish';
import { getPreview } from '../../reducers/preview';
import { getPublish } from '../../reducers/publish';
import { getState } from '../../reducers';
import { currentUserDefaults } from '../../shared/constants/auth';
import AnyFunction = Globals.AnyFunction;
import TargetedEvent = Globals.TargetedEvent;
import mergeDefaults = Globals.mergeDefaults;
import Auth from '../../shared/services/auth';
import '../../scss/containers/_PublishToCampingPage.scss';
import RidbLink from '../../shared/components/RidbLink';
import { StateStatus } from '../../shared/utils/async';

type PublishToCampingPageProps = {
    currentUser?: ICurrentUser;
    preview?: any;
    publish?: any;
    statesPreview?: any;
    statesPublish?: any;
    fetchData: AnyFunction;
    publishData: AnyFunction;
};

function PublishToCampingPage(props: PublishToCampingPageProps) {
    const [assetId, setAssetId] = useState('');
    const [assetType, setAssetType] = useState('');
    const [validAssetId, setValidAssetId] = useState(true);
    const [validAssetType, setValidAssetType] = useState(true);
    const [showAgreementModal, setShowAgreementModal] = useState(false);
    const [showPreviewNotification, setShowPreviewNotification] = useState(false);
    const [showPublishNotification, setShowPublishNotification] = useState(false);
    const [searching, setSearching] = useState(false);

    useEffect(() => {
        clearForm();
    }, []);

    const clearForm = () => {
        setAssetId('');
        setAssetType('');
        setValidAssetId(true);
        setValidAssetType(true);
        setShowAgreementModal(false);
        setSearching(false);
    };

    const toggleShowAgreementModal = () => {
        setShowAgreementModal(!showAgreementModal);
    };

    const displayPreviewNotification = () => {
        setShowPreviewNotification(true);
        setTimeout(() => {
            setShowPreviewNotification(false);
        }, 5000);
    };

    const displayPublishNotification = () => {
        setShowPublishNotification(true);
        setTimeout(() => {
            setShowPublishNotification(false);
        }, 5000);
    };

    const handleAssetTypeChange = (event: TargetedEvent) => {
        const { value } = event.target;
        if (!value || value.trim().length === 0) {
            setValidAssetType(false);
            setAssetType(null);
        } else {
            setValidAssetType(true);
            setAssetType(value);
        }
    };

    const handleAssetIdChange = (event: TargetedEvent) => {
        const { value } = event.target;
        if (!value || value.trim().length === 0) {
            setValidAssetId(false);
            setAssetId(null);
        } else {
            setValidAssetId(true);
            setAssetId(value);
        }
    };

    const handleSearch = async () => {
        setSearching(true);
        try {
            await props.fetchData(assetType, assetId);
        } catch (error) {
            console.log(error);
        }
        displayPreviewNotification();
    };

    const handlePublish = async () => {
        toggleShowAgreementModal();
        try {
            await props.publishData(assetType, assetId);
        } catch (error) {
            console.log(error);
        }
        displayPublishNotification();
        clearForm();
    };

    const {
        statesPreview: { preview: statesPreview },
        statesPublish: { publish: statesPublish },
        preview,
        publish,
        currentUser,
    } = props;

    const previewLoading = statesPreview === StateStatus.LOADING;
    const previewReady = statesPreview === StateStatus.READY;
    const previewError = statesPreview === StateStatus.ERROR;
    const publishLoading = statesPublish === StateStatus.LOADING;
    const publishReady = statesPublish === StateStatus.READY;
    const publishError = statesPublish === StateStatus.ERROR;

    const { loggedIn } = mergeDefaults<ICurrentUser>(currentUser, currentUserDefaults);

    if (!loggedIn && !Auth.isLoggedIn()) {
        return (
            <div>
                Please <RidbLink to="/login">login</RidbLink> to Publish to Camping.
            </div>
        );
    }

    const isDisabled = !assetType || !assetId;
    const showResult = previewReady && !isDisabled && !publishLoading && searching;

    return (
        <SkipNavContent>
            <HelmetWrapper title="RIDB Publish To Camping" />
            <div>
                <Spacer size="sm" />
                <div>
                    <FlexRow>
                        <FlexCol xs={12}>
                            <TextBlock className="title" width="xl" alignX="center">
                                Publish to Camping
                            </TextBlock>
                            <Spacer size="md" />
                            <TextBlock className="body-text" width="xl" alignX="center">
                                <div className="publish-page-container">
                                    <div className="row">
                                        <div className="cell">
                                            <Select
                                                isRequired
                                                id="profile-rtype"
                                                label="Asset Type"
                                                isInvalid={!validAssetType}
                                                onChange={handleAssetTypeChange}
                                                className="full-width"
                                                value={assetType}
                                            >
                                                <SelectOption key="empty" value="">
                                                    Select a Asset Type
                                                </SelectOption>
                                                {['Campsite', 'Facility'].map((value) => (
                                                    <SelectOption
                                                        key={value}
                                                        value={value}
                                                        label=""
                                                    >
                                                        {value}
                                                    </SelectOption>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className="cell">
                                            <TextField
                                                id="profile-rid"
                                                label="Asset ID"
                                                isInvalid={!validAssetId}
                                                onChange={handleAssetIdChange}
                                                isRequired
                                                className="full-width"
                                                value={assetId}
                                            />
                                        </div>
                                        <div className="cell search">
                                            <span className="space" />
                                            <Button
                                                shouldFitContainer
                                                isDisabled={isDisabled}
                                                onClick={handleSearch}
                                            >
                                                Search
                                            </Button>
                                        </div>
                                    </div>
                                    {(previewLoading || publishLoading) && (
                                        <Spinner isCentered size="xl" />
                                    )}
                                    {showResult && (
                                        <>
                                            <div className="row">
                                                <div className="cell">
                                                    <TextArea
                                                        value={JSON.stringify(preview)}
                                                        id="profile-row"
                                                        className="mb-1"
                                                        label="Preview Data"
                                                        isRequired
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="cell buttons">
                                                    <Button
                                                        onClick={toggleShowAgreementModal}
                                                    >
                                                        Publish
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {showAgreementModal && (
                                        <div>
                                            <ClickWrapModal
                                                shouldPresent
                                                onCancel={toggleShowAgreementModal}
                                                onContinue={handlePublish}
                                            />
                                        </div>
                                    )}
                                    {previewError && showPreviewNotification && (
                                        <Notification
                                            className="error-box"
                                            title="Error:"
                                            type="error"
                                        >
                                            An error occurred while executing your search.
                                            Please try again later.
                                        </Notification>
                                    )}
                                    {publishError &&
                                        showPublishNotification &&
                                        !previewError && (
                                            <Notification
                                                className="error-box"
                                                title="Error:"
                                                type="error"
                                            >
                                                An error occurred while publishing
                                                information to Camping. Please try again
                                                later.
                                            </Notification>
                                        )}
                                    {publishReady &&
                                        showPublishNotification &&
                                        !previewError && (
                                            <Notification
                                                className="error-box"
                                                title="Success:"
                                                type="success"
                                            >
                                                {publish.MESSAGE}
                                            </Notification>
                                        )}
                                </div>
                                <br />
                                In order to <b>go back</b>&nbsp;
                                <RidbLink to="/standards">click here</RidbLink>.
                                <hr className="standards-hr-grey" />
                            </TextBlock>
                        </FlexCol>
                    </FlexRow>
                </div>
            </div>
        </SkipNavContent>
    );
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
    preview: getPreview(state),
    publish: getPublish(state),
    statesPreview: getState(state, 'preview'),
    statesPublish: getState(state, 'publish'),
});

const mapDispatchToProps = (dispatch) => ({
    fetchData: (assetType, assetId) => dispatch(fetchPreview(assetType, assetId)),
    publishData: (assetType, assetId) => dispatch(publishPreview(assetType, assetId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishToCampingPage);
