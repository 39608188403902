/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { get } from 'lodash';
import { FETCH_ASSETS, RIDB_ACTION_PREFIX } from '../shared/constants/actions';
import { asyncDefaults, makeAsyncReducer, mergeReducers } from '../shared/utils/async';

const fetchAssets = makeAsyncReducer(FETCH_ASSETS, { initialData: [] });

export const getAssets = (state) => get(state, 'portal.assets.data.data');
export const getAssetCount = (state) => get(state, 'portal.assets.data.total_results');

export default mergeReducers(
    asyncDefaults({ data: [], total_results: 0 }),
    RIDB_ACTION_PREFIX,
    fetchAssets
);
