/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { makeAsyncAction } from '../shared/utils/async';
import { FETCH_ORGANIZATIONS } from '../shared/constants/actions';
import API from '../shared/api';

export const fetchOrganizations = makeAsyncAction(
    FETCH_ORGANIZATIONS,
    () => () => API.fetchOrganizations(),
    'orgs'
);
