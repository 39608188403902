/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, isArray } from 'lodash';
import moment from 'moment';
import {
    Button,
    HelmetWrapper,
    SkipNavContent,
    Spinner,
    TextField,
    Notification,
} from 'sarsaparilla';
import { getCurrentUser } from '../../reducers/currentUser';
import { ICurrentUser } from '../../shared/types/auth/currentUser';
import { Globals } from '../../shared/globals';
import {
    disableMachineKey,
    generateMachineKeys,
    fetchMachineKeys,
} from '../../actions/apiKeys';
import { getMachineKeys } from '../../reducers/apiKey';
import { currentUserDefaults } from '../../shared/constants/auth';
import AnyFunction = Globals.AnyFunction;
import mergeDefaults = Globals.mergeDefaults;
import Auth from '../../shared/services/auth';
import '../../scss/containers/_MachineTokensPage.scss';
import RidbLink from '../../shared/components/RidbLink';
import { IMachineApiKey } from '../../shared/types/auth/machineApiKey';
import { getState } from '../../reducers';
import { StateStatus } from '../../shared/utils/async';

type MachineTokensPageProps = {
    currentUser?: ICurrentUser;
    apiKeys: IMachineApiKey[];
    states?: any;
    disableMachineKey: AnyFunction;
    generateMachineKeys: AnyFunction;
    fetchMachineKeys: AnyFunction;
};

function MachineTokensPage(props: MachineTokensPageProps) {
    const [showNotification, setShowNotification] = useState(false);

    const {
        states: { apiKeys: statesApiKeys },
        apiKeys,
        disableMachineKey,
        fetchMachineKeys,
        generateMachineKeys,
    } = props;

    const { currentUser } = props;
    const { isDeveloper, loggedIn } = mergeDefaults<ICurrentUser>(
        currentUser,
        currentUserDefaults
    );
    const loading = statesApiKeys !== StateStatus.READY;
    const error = statesApiKeys === StateStatus.ERROR;

    const displayNotification = () => {
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 5000);
    };

    const generate = async () => {
        await generateMachineKeys();
        await fetchMachineKeys();
    };

    const disable = async (id) => {
        await disableMachineKey(id);
        await fetchMachineKeys();
        displayNotification();
    };

    useEffect(() => {
        fetchMachineKeys();
    }, []);

    if (!loggedIn && !Auth.isLoggedIn()) {
        return (
            <div>
                Please <RidbLink to="/login">login</RidbLink> to view your profile
            </div>
        );
    }

    if (loading && !error) {
        return <Spinner isCentered size="xl" />;
    }

    const filteredKeys = isArray(apiKeys)
        ? apiKeys.filter((key) => !isEmpty(key.id))
        : [];

    return (
        <SkipNavContent>
            <HelmetWrapper title="RIDB Machine API Tokens" />
            <main role="main" className="machine-tokens-page">
                <h1>Machine API Tokens</h1>
                <div className="modal-box">
                    {error && (
                        <Notification className="error-box" title="Error:" type="error">
                            An error occurred while creating the new machine token. Please
                            try again later.
                        </Notification>
                    )}
                    {showNotification && (
                        <Notification
                            className="error-box"
                            title="Success:"
                            type="success"
                        >
                            Expiration date updated successfully.
                        </Notification>
                    )}
                    {!!filteredKeys?.length &&
                        filteredKeys.map((apiKey, index) => (
                            <div className="row" key={apiKey.id}>
                                <TextField
                                    id={`api-key-${index}`}
                                    label={`Machine APIKey #${index + 1}${
                                        apiKey.expiration_date > 0
                                            ? ` (Expires in ${`${moment
                                                  .unix(apiKey.expiration_date)
                                                  .format(
                                                      'MM/DD/YYYY [at] hh:mm a z'
                                                  )}`.trim()}):`
                                            : ':'
                                    }`}
                                    value={apiKey.id}
                                    isDisabled
                                />
                                <div className="disable-key-button-wrapper">
                                    <Button
                                        className="disable-key-button"
                                        appearance="primary"
                                        onClick={() => {
                                            disable(apiKey.id);
                                        }}
                                    >
                                        Disable
                                    </Button>
                                </div>
                            </div>
                        ))}

                    {!filteredKeys.length && (
                        <div className="row">
                            <TextField
                                id="api-key"
                                label="Machine APIKey:"
                                value="No machine APIKeys found"
                                isDisabled
                            />
                        </div>
                    )}

                    <div className="row buttons">
                        {isDeveloper && (
                            <div className="api-key-button-wrapper">
                                <Button
                                    className="api-key-button"
                                    appearance="tertiary"
                                    onClick={() => {
                                        generate();
                                    }}
                                >
                                    Generate New APIKey
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </SkipNavContent>
    );
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
    apiKeys: getMachineKeys(state),
    states: getState(state, 'apiKeys'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            disableMachineKey,
            generateMachineKeys,
            fetchMachineKeys,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MachineTokensPage);
