/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { jwtDecode } from 'jwt-decode';
import { RidbHeaders } from '../constants/headers';
import { Globals } from '../globals';
import { AuthLocalStorageKeys } from '../constants/auth';
import iterateEnum = Globals.iterateEnum;
import Headers = Globals.Headers;
import { LocalStorage } from './localStorage';

export enum AuthTargetedTypes {
    USER = 'user',
    TOKEN = 'token',
    API_KEY = 'apiKey',
    API_KEYS = 'apiKeys',
}

class Auth {
    constructor(
        private readonly storage = LocalStorage,
        private readonly key = 'developer-session'
    ) {}

    /**
     *
     * @param type, type of data retrieved
     * @param data
     */
    public target<T = any>(type: AuthTargetedTypes, data?: any): T | object {
        const key = `${this.key}-${type}`;
        if (data) {
            this.setData(key, data);
        }
        const info = this.getData<T>(key);
        switch (type) {
            case 'user':
                return info || {};
            default:
                return info;
        }
    }

    public clearData = () => {
        iterateEnum(AuthLocalStorageKeys, (key, value) => {
            this.storage.removeItem(value);
        });
    };

    public isLoggedIn() {
        const token = this.target(AuthTargetedTypes.TOKEN);
        if (token) {
            const jwt = jwtDecode(token);
            // Not expired
            if (jwt.exp > Date.now() / 1000) {
                return true;
            }
        }
        // Expired
        this.clearData();
        return false;
    }

    private setData(key: string, data) {
        if (this.storage.getItem(key)) {
            this.storage.removeItem(key);
        }
        this.storage.setItem(key, JSON.stringify(data));
    }

    private getData<T = any>(key): T {
        const data = this.storage.getItem(key);
        if (data) {
            let final = data;
            try {
                final = JSON.parse(data);
            } catch (ignored) {
                console.error(ignored);
            }
            return final;
        }
        return null;
    }

    private getAuthHeader = () => `Bearer ${this.target(AuthTargetedTypes.TOKEN)}`;

    public getHeaders = (headers: Headers): Headers => {
        const authToken = this.target(AuthTargetedTypes.TOKEN);
        const apiKey = this.target(AuthTargetedTypes.API_KEY);
        const finalHeaders = { ...headers };
        if (authToken && authToken !== 'undefined') {
            finalHeaders[RidbHeaders.AUTH] = this.getAuthHeader();
        }
        if (apiKey && apiKey !== 'undefined') {
            finalHeaders[RidbHeaders.API_KEY] = apiKey;
        }
        return finalHeaders;
    };
}

export default new Auth();
