/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component } from 'react';
import { get, uniqueId } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Button,
    CountryPicker,
    FlexCol,
    FlexRow,
    Icon,
    StatePicker,
    TextField,
    ZIPCodeField,
} from 'sarsaparilla';
import { IAddress } from '../../../../shared/types/asset/address';
import { Globals } from '../../../../shared/globals';
import AnyFunction = Globals.AnyFunction;
import {
    ADDRESS1_PATH,
    ADDRESS2_PATH,
    ADDRESS3_PATH,
    CITY_PATH,
    COUNTRY_PATH,
    POSTAL_CODE_PATH,
    STATE_PATH,
} from '../../../../shared/constants/asset/address';
import { stripHTML } from '../../../../shared/utils/string';
import { addChange, clearChange, getRef } from '../../../../actions/changes';
import { reduceAndRestructure } from '../../../../shared/utils/asset';
import TargetedEvent = Globals.TargetedEvent;
import emptyFunction = Globals.emptyFunction;
import '../../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';

type AddressEditProps = {
    getRef: AnyFunction;
    addChange: AnyFunction;
    clearChange: AnyFunction;
    index?: number;
    value?: IAddress;
    addressType?: string;
    onRemove?: AnyFunction;
    onChange?: AnyFunction;
};

type AddressEditState = {
    record: any;
    ref: any;
};

class AddressEdit extends Component<AddressEditProps, AddressEditState> {
    readonly textFields = [
        {
            column: { xs: 12, className: 'mb-1' },
            textField: {
                label: 'Street Address',
                id: (index: number) => `street_address-${index}`,
                value: (record: any) => stripHTML(get(record, ADDRESS1_PATH)),
                onChange: (e: TargetedEvent) => this.handleChange(e, ADDRESS1_PATH),
                isRequired: true,
            },
        },
        {
            column: { xs: 12, className: 'mb-1' },
            textField: {
                label: 'Street Address (cont.)',
                id: (index: number) => `street_address_cont-${index}`,
                value: (record: any) => stripHTML(get(record, ADDRESS2_PATH)),
                onChange: (e: TargetedEvent) => this.handleChange(e, ADDRESS2_PATH),
            },
        },
        {
            column: { xs: 12, className: 'mb-1' },
            textField: {
                label: 'Street Address (cont.)',
                id: (index: number) => `street_address_cont-${index}`,
                value: (record: any) => stripHTML(get(record, ADDRESS3_PATH)),
                onChange: (e: TargetedEvent) => this.handleChange(e, ADDRESS3_PATH),
            },
        },
        {
            column: { md: 4, className: 'mb-1' },
            textField: {
                label: 'City',
                id: (index: number) => `city-${index}`,
                value: (record: any) => stripHTML(get(record, CITY_PATH)),
                onChange: (e: TargetedEvent) => this.handleChange(e, CITY_PATH),
            },
        },
    ];

    constructor(props) {
        super(props);
        const { value: record, getRef, addressType, addChange } = props;
        const paths = [STATE_PATH, COUNTRY_PATH, ADDRESS1_PATH];
        const ref = getRef(
            addressType,
            { id: record.id },
            void 0,
            reduceAndRestructure(paths, record)
        );
        this.state = { record, ref };

        if (!record[STATE_PATH]) {
            addChange(ref, { [STATE_PATH]: '' });
        }
        if (!record[ADDRESS1_PATH]) {
            addChange(ref, { [ADDRESS1_PATH]: '' });
        }
        if (!record[COUNTRY_PATH]) {
            addChange(ref, { [COUNTRY_PATH]: '' });
        }
    }

    handleChange = (event: TargetedEvent, path) => {
        const { addChange, onChange = emptyFunction } = this.props;
        const { value } = event.target;
        const change = { [path]: value };

        this.setState(
            (prevState) => {
                const record = { ...prevState.record, ...change };
                onChange(record);
                return {
                    ...prevState,
                    record,
                };
            },
            () => addChange(this.state.ref, change)
        );
    };

    doRemove = () => {
        const {
            value: { id, _key },
            addChange,
            clearChange,
            onRemove,
        } = this.props;
        const { ref } = this.state;
        id ? addChange(ref, { _delete: true }) : clearChange(ref);
        onRemove(_key);
    };

    render() {
        const {
            props: { index },
            state: { record },
        } = this;
        return (
            <div className="section-box">
                <Button
                    appearance="subtle-danger"
                    onClick={this.doRemove}
                    className="delete-button"
                    iconBeforeElement={<Icon iconName="close" />}
                />
                <FlexRow>
                    <FlexCol xs={12} className="mb-1">
                        <CountryPicker
                            label="Country"
                            id={`country_address-${index}`}
                            value={get(record, COUNTRY_PATH)}
                            onChange={(e) => this.handleChange(e, COUNTRY_PATH)}
                            isRequired
                        />
                    </FlexCol>
                    {this.textFields.map(({ textField, column }, index) => (
                        <FlexCol {...column} key={index}>
                            <TextField
                                {...textField}
                                id={textField.id(index)}
                                value={textField.value(record)}
                            />
                        </FlexCol>
                    ))}
                    <FlexCol md={4} className="mb-1">
                        <StatePicker
                            label="State"
                            id={`state-${index}`}
                            value={get(record, STATE_PATH)}
                            onChange={(e) => this.handleChange(e, STATE_PATH)}
                            isRequired
                        />
                    </FlexCol>
                    <FlexCol md={4} className="mb-1">
                        <ZIPCodeField
                            label="Postal Code"
                            id={`postal_code-${index}`}
                            value={get(record, POSTAL_CODE_PATH)}
                            onChange={(e) => this.handleChange(e, POSTAL_CODE_PATH)}
                        />
                    </FlexCol>
                </FlexRow>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getRef,
            addChange,
            clearChange,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddressEdit);
