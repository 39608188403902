/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Globals } from '../globals';
import MillisecondTimes = Globals.MillisecondTimes;
import AnyFunction = Globals.AnyFunction;

type CacheItem = { value: string; expiration: number };

class RIDBCache {
    readonly ENABLED = true;

    public KEY = `ridb-cache-${process.env.ENV}`;

    public EXPIRATION = MillisecondTimes.HOUR * 6;

    public cache: Map<string, CacheItem> = new Map<string, CacheItem>();

    public loaded = false;

    // array of functions to be triggered on load, coming from API requests made before the cache loaded
    private actionsOnLoad: AnyFunction[] = [];

    /**@Migration TODO - Add compression */
    constructor(
        private readonly storage: Storage,
        public options: { key?: string } = {}
    ) {
        if (options?.key) {
            this.KEY = options.key;
        }
        if (this.ENABLED) {
            window.onbeforeunload = () => {
                if (!storage.getItem(this.KEY)) {
                    this.writeToStorage.bind(this);
                }
            };
            window.onload = this.readOnLoad.bind(this);
            window.localStorage.__proto__ = Object.create(Storage.prototype);
            localStorage.__proto__.clear = function clear() {
                // This is to prevent sarsa from clearing all local Storage on refresh.
            };
        }
        // Useful command for debugging -> window.cache.check() on browser console.
        if (process.env.ENV === 'local' || process.env.ENV === 'development') {
            const cacheOps = {
                check: () => this.cache,
            };
            Window['cache'] = cacheOps;
            window['cache'] = cacheOps;
        }
    }

    set(key: string, value: any) {
        const set = () => {
            this.cache.set(key, {
                value: JSON.stringify(value),
                expiration: new Date().setTime(new Date().getTime() + this.EXPIRATION),
            });
            this.writeToStorage();
        };
        if (this.loaded) {
            return set();
        }
        this.actionsOnLoad.push(set.bind(this));
    }

    get(key: string) {
        const item = this.cache.get(key);
        if (!item) {
            return void 0;
        }
        return JSON.parse(item.value);
    }

    isExpired(key: string) {
        const item = this.cache.get(key);
        return !item || item.expiration < Date.now();
    }

    handle(request: string, apiCall: AnyFunction) {
        if (!this.ENABLED) {
            return apiCall();
        }
        if (!this.loaded) {
            const cache = this.cacheFromStorage();
            if (cache) {
                this.cache = cache;
                this.trimExpired();
                this.loaded = true;
                const value = this.cache.get(request);
                if (value && value.expiration > Date.now()) {
                    const item = this.get(request);
                    return Promise.resolve(item);
                }
            }
        } else if (!this.isExpired(request)) {
            const item = this.get(request);
            return Promise.resolve(item);
        }
        return apiCall()
            .catch((e) => e)
            .then((d) => {
                // do not cache error responses
                if (d.name !== 'Error') {
                    this.set(request, d);
                }
                return d;
            });
    }

    writeToStorage() {
        const cacheString = JSON.stringify(Array.from(this.cache));
        this.storage.setItem(this.KEY, cacheString);
    }

    cacheFromStorage(): Map<string, CacheItem> {
        try {
            const cacheString = this.storage.getItem(this.KEY);
            if (cacheString) {
                const cache: Map<string, CacheItem> = new Map(JSON.parse(cacheString));
                return cache || void 0;
            }
        } catch (e) {
            return void 0;
        }
    }

    trimExpired() {
        this.cache.forEach((value, key, map) => {
            if (+value.expiration < Date.now()) {
                map.delete(key);
            }
        });
    }

    readOnLoad() {
        if (!this.loaded) {
            const cache = this.cacheFromStorage();
            if (cache) {
                this.cache = cache;
                this.trimExpired();
                this.loaded = true;
            }
            for (const action of this.actionsOnLoad) {
                try {
                    action();
                } catch (ignored) {
                    console.error(ignored);
                }
            }
            this.actionsOnLoad = [];
        }
    }
}

export default new RIDBCache(window.localStorage);
