/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce } from 'lodash';
import { FlexCol, FlexContainer, FlexRow, Icon } from 'sarsaparilla';
import { Globals } from '../../shared/globals';
import AnyFunction = Globals.AnyFunction;
import IconTextInput from '../../shared/components/IconTextInput';
import mergeDefaults = Globals.mergeDefaults;
import { getUserSearchErrors, getUserSearchTerm } from '../../reducers/userSearch';
import { saveUserSearchTerm } from '../../actions/userSearch';
import { fetchAccount } from '../../actions/account';
import { useMountEffect } from '../../shared/hooks/useMountEffect';
import '../../scss/containers/UserAdminPage/_UserAdminPage.scss';

const defaults = { value: '', errors: [] };

type UserAdminSearchFormProps = {
    value: string;
    errors: string[];
    saveSearch: AnyFunction;
    fetchAccount: AnyFunction;
};

export function UserAdminSearchForm(props: Partial<UserAdminSearchFormProps>) {
    const isCancelled = useRef(false);
    const {
        saveSearch,
        fetchAccount: propsFetchAccount,
        value,
        errors,
    } = mergeDefaults(props, defaults);
    const [validationErrors, setValidationErrors] = useState([]);

    const fetchAccount = (terms) =>
        propsFetchAccount({ query: terms }).catch(({ message }) => {
            const errorMessage = message.toLowerCase();
            if (errorMessage.includes('no records found')) {
                const validation = [...validationErrors, `${terms} does not exist.`];
                if (!isCancelled.current) {
                    setValidationErrors(validation);
                }
            }
        });

    let doFetchAccount;

    useMountEffect(() => {
        doFetchAccount = debounce(fetchAccount, 350);
        return () => {
            isCancelled.current = true;
        };
    });

    const saveTerm = (term: string) => {
        if (saveSearch(term)) {
            return doFetchAccount(term);
        }
        doFetchAccount.cancel();
    };

    const handleKeyPress = ({ key }) => {
        if (key === 'Enter') {
            doFetchAccount.flush();
        }
    };

    const finalErrors = [...validationErrors, ...errors];
    return (
        <FlexContainer>
            <FlexRow>
                <FlexCol>
                    <IconTextInput
                        id="user-search-string"
                        value={value}
                        iconLeft={() => <Icon iconName="search" />}
                        label="User Search"
                        onChange={saveTerm}
                        onKeyPress={handleKeyPress}
                        placeholder="User Email"
                    />
                    {finalErrors.length !== 0 && (
                        <ul className="errors">
                            {finalErrors.map((error, i) => (
                                <li key={`error-${i}`}>{error}</li>
                            ))}
                        </ul>
                    )}
                </FlexCol>
            </FlexRow>
        </FlexContainer>
    );
}

const mapStateToProps = (state) => ({
    value: getUserSearchTerm(state),
    errors: getUserSearchErrors(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            saveSearch: saveUserSearchTerm,
            fetchAccount,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserAdminSearchForm);
