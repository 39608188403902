/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

export const addressDefaults = {
    id: null,
    address1: '',
    address2: '',
    address3: '',
    city: '',
    stateCode: '',
    postalCode: '',
    countryCode: '',
};

export const ADDRESS2_PATH = 'address2';
export const ADDRESS3_PATH = 'address3';
export const ADDRESS1_PATH = 'address1';
export const CITY_PATH = 'city';
export const STATE_PATH = 'stateCode';
export const POSTAL_CODE_PATH = 'postalCode';
export const COUNTRY_PATH = 'countryCode';
