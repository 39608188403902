/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { makeAsyncAction } from '../shared/utils/async';
import { FETCH_CHANGE_LOG_ITEMS } from '../shared/constants/actions';
import API from '../shared/api';

export const fetchChangeLogItems = makeAsyncAction(
    FETCH_CHANGE_LOG_ITEMS,
    () => () => API.fetchChangeLogItems(),
    'data'
);
