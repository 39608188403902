/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

export const NAME_PATH = 'name';
export const ID_PATH = 'id';
export const EMAIL_PATH = 'email';
export const FEE_DESCRIPTION_PATH = 'feeDescription';
export const LEGACY_ID_PATH = 'legacyId';
export const ORG_ID_PATH = 'orgId';
export const ORG_REF_ID_PATH = 'orgRefId';
export const LATITUDE_PATH = 'latitude';
export const LONGITUDE_PATH = 'longitude';
export const PHONE_PATH = 'phone';
export const DESCRIPTION_PATH = 'description';
export const DIRECTIONS_PATH = 'directions';
export const ACCESSIBILITY_TEXT_PATH = 'accessibilityText';
export const KEYWORDS_PATH = 'keywords';
export const STAY_LIMIT_PATH = 'stayLimit';

export const newAssetDefaults = {
    activities: [],
    addresses: [],
    links: [],
    media: [],
};

export const getNewChanges = (originalAsset, updatedAsset) => {
    const changes = [];
    for (const key in originalAsset) {
        const originalValue = originalAsset[key];
        // skip any functions, objects, and arrays
        if (
            originalValue &&
            (typeof originalValue === 'object' ||
                typeof originalValue === 'function' ||
                Array.isArray(originalValue))
        ) {
            continue;
        }

        const updatedValue = updatedAsset[key];
        if (
            typeof updatedValue === 'boolean' &&
            typeof originalValue === 'boolean' &&
            updatedValue !== originalValue
        ) {
            changes.push({ [key]: updatedValue });
        } else if (typeof updatedValue === 'boolean' && updatedValue !== originalValue) {
            if (
                (originalValue === 'No' && originalValue) ||
                (originalValue === 'Yes' && originalValue)
            ) {
                changes.push({ [key]: updatedValue });
            }
        } else if (updatedValue && updatedValue !== originalValue) {
            changes.push({ [key]: updatedValue });
        }
    }
    return changes;
};
