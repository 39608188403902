/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    FETCH_MACHINE_KEYS,
    GENERATE_MACHINE_KEYS,
    RIDB_ACTION_PREFIX,
    DISABLE_MACHINE_KEY,
} from '../shared/constants/actions';
import { asyncDefaults, makeAsyncReducer, mergeReducers } from '../shared/utils/async';

const fetchMachineKeys = makeAsyncReducer(FETCH_MACHINE_KEYS);

const generateMachineKeys = makeAsyncReducer(GENERATE_MACHINE_KEYS);

const disableMachineKey = makeAsyncReducer(DISABLE_MACHINE_KEY);

export const getMachineKeys = (state) => state.portal.apiKeys.data;

export default mergeReducers(
    asyncDefaults([]),
    RIDB_ACTION_PREFIX,
    fetchMachineKeys,
    generateMachineKeys,
    disableMachineKey
);
