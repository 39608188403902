/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { Navigate } from 'react-router-dom-v5-compat';

import { Spinner } from 'sarsaparilla';

import Auth, { AuthTargetedTypes } from '../services/auth';

interface RequireAuthRidbProps {
    children: React.ReactNode;
    requiresWriteAccess?: boolean;
    requiresAdminAccess?: boolean;
}

/**
 * Wrap routes that require the user to be logged in. If the user is not logged in,
 * they will be redirected to the home page.
 *
 * @example
 * <RequireAuthRidb>
 *     <ProfilePage />
 * </RequireAuthRidb>
 */
export function RequireAuthRidb({
    children,
    requiresWriteAccess,
    requiresAdminAccess,
}: RequireAuthRidbProps) {
    const isLoggedIn = Auth.isLoggedIn();
    const user = Auth.target(AuthTargetedTypes.USER);

    const hasWritePermissions = requiresWriteAccess
        ? isLoggedIn && Boolean(user?.apiKey?.writeAccess)
        : isLoggedIn;

    const hasAdminPermissions = requiresAdminAccess
        ? isLoggedIn && Boolean(user?.apiKey?.adminAccess)
        : isLoggedIn;

    if (isLoggedIn && hasWritePermissions && hasAdminPermissions) {
        return children;
    }

    return <Navigate to="/landing" />;
}
