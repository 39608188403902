/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState } from 'react';
import { Label } from 'sarsaparilla';
import {
    getCssClassesFromProps,
    getIdFromProps,
    IWrappedInputsBaseProps,
} from './wrappedInputs';
import '../../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';

interface CheckBoxInputProps extends IWrappedInputsBaseProps {
    value?: any;
}

function AssetEditCheckBoxInput(props: CheckBoxInputProps) {
    const { id: propsId = null, field, required, onChange, value = '', label } = props;
    const [checked, setChecked] = useState(!!value);
    const id = getIdFromProps(propsId, field, 'checkbox-');
    const css = getCssClassesFromProps(required, false, 'checkbox-input');
    const change = () => {
        setChecked(!checked);
        onChange({ target: { value: !checked, dataset: { field } } });
    };

    return (
        <div className={css}>
            <Label className="rec-label-checkbox" htmlFor={id}>
                <input
                    className="rec-input-hide"
                    type="checkbox"
                    id={id}
                    data-field={field}
                    checked={checked}
                    onChange={change}
                />
                <span className="rec-input-checkbox" />
                {label}
            </Label>
        </div>
    );
}

export default AssetEditCheckBoxInput;
