/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom-v5-compat';

import { Tabs, Divider, TabPanel, ContentBlock } from 'sarsaparilla';
import {
    enableDeveloperAccess,
    fetchAccount,
    updateAccount,
} from '../../actions/account';
import { fetchOrganizations } from '../../actions/organization';
import { getState } from '../../reducers';
import { getAccount } from '../../reducers/accounts';
import { getOrganizations } from '../../reducers/organizations';
import { IAccount } from '../../shared/types/auth/account';
import { IApiKey } from '../../shared/types/auth/apiKey';
import { IOrganization } from '../../shared/types/asset/organization';
import { Globals } from '../../shared/globals';
import UserAdminSearchForm from './UserAdminSearchForm';
import { flattenOrgs, orgsToOptions } from '../../shared/utils/organization';
import { StateStatus } from '../../shared/utils/async';
import UserAdminUserDisplay from './UserAdminUserDisplay';
import LoadingWrapper from '../../shared/components/LoadingWrapper';
import { getCurrentUser } from '../../reducers/currentUser';
import { ICurrentUser } from '../../shared/types/auth/currentUser';
import { useMountEffect } from '../../shared/hooks/useMountEffect';
import Auth, { AuthTargetedTypes } from '../../shared/services/auth';
import AnyFunction = Globals.AnyFunction;
import mergeDefaults = Globals.mergeDefaults;
import '../../scss/containers/UserAdminPage/_UserAdminPage.scss';

type UserAdminPageProps = {
    account: {
        account: IAccount;
        apiKey: IApiKey;
    };
    organizations?: IOrganization[];
    states: { [key: string]: number };
    fetchOrganizations: AnyFunction;
    enableDeveloperAccess: AnyFunction;
    fetchAccount: AnyFunction;
    updateAccount: AnyFunction;
    currentUser?: ICurrentUser;
};

function UserAdminPage(props: UserAdminPageProps) {
    const {
        organizations,
        states: { accounts },
        account,
        currentUser,
    } = props;
    const [initiatedLoad, setInitiatedLoad] = useState(false);
    const navigate = useNavigate();

    useMountEffect(() => {
        const {
            states: { organizations },
            fetchOrganizations,
        } = props;
        if (organizations !== StateStatus.READY && !initiatedLoad) {
            setInitiatedLoad(true);
            fetchOrganizations();
        }
    });

    if (!currentUser || !currentUser.apiKey || !currentUser.apiKey.apiKey) {
        navigate('/landing');
        return <div>You need to be logged in to view this page</div>;
    }

    const { account: currentUserAccount, apiKey: currentUserApiKey } = currentUser;
    const storedApiKey = Auth.target(AuthTargetedTypes.API_KEY);
    const currentUserAccountDetails = {
        account: currentUserAccount,
        apiKey:
            (currentUserApiKey.apiKey === '' && storedApiKey) ||
            currentUserApiKey.orgId !== storedApiKey.orgId
                ? mergeDefaults({ apiKey: storedApiKey }, currentUserApiKey)
                : currentUserApiKey,
    };
    const orgOptions = orgsToOptions(flattenOrgs(organizations));
    const disabled = accounts !== StateStatus.READY;
    const isLoading = accounts === StateStatus.LOADING;

    const enableDeveloperAccess = () => {
        const {
            account: {
                account: { accountId, email },
            },
            enableDeveloperAccess,
            fetchAccount,
        } = props;
        return enableDeveloperAccess(accountId).then(() =>
            fetchAccount({ query: email })
        );
    };

    const updateAccount = (updatedApiKey: IApiKey) => {
        const {
            currentUser: {
                account: { email },
            },
            updateAccount,
            fetchAccount,
        } = props;
        return updateAccount(updatedApiKey).then(() => {
            Auth.target(AuthTargetedTypes.API_KEY, updatedApiKey);
            return fetchAccount({ query: email });
        });
    };

    return (
        <ContentBlock className="search-page">
            <Tabs appearance="block" stretchTabsToFit={false}>
                <TabPanel label="Your account">
                    <LoadingWrapper loading={isLoading} size="lg">
                        <UserAdminUserDisplay
                            account={currentUserAccountDetails}
                            orgOptions={orgOptions}
                            isDisabled={false}
                            isLoading={isLoading}
                            onEnable={enableDeveloperAccess}
                            onChange={updateAccount}
                        />
                    </LoadingWrapper>
                </TabPanel>
                <TabPanel label="Search users">
                    <LoadingWrapper loading={isLoading} size="lg">
                        <UserAdminSearchForm />
                        <Divider />
                        <UserAdminUserDisplay
                            account={account}
                            orgOptions={orgOptions}
                            isDisabled={disabled}
                            isLoading={isLoading}
                            onEnable={enableDeveloperAccess}
                            onChange={updateAccount}
                            fetchIfDifferent
                        />
                    </LoadingWrapper>
                </TabPanel>
            </Tabs>
        </ContentBlock>
    );
}

const mapStateToProps = (state) => ({
    account: getAccount(state),
    organizations: getOrganizations(state),
    states: getState(state, 'accounts', 'organizations'),
    currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchOrganizations,
            fetchAccount,
            enableDeveloperAccess,
            updateAccount,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserAdminPage);
