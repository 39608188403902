/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

export const unitedStates = [
    {
        label: 'Alabama',
        abbreviation: 'AL',
        value: 'Alabama',
    },
    {
        label: 'Alaska',
        abbreviation: 'AK',
        value: 'Alaska',
    },
    {
        label: 'Arizona',
        abbreviation: 'AZ',
        value: 'Arizona',
    },
    {
        label: 'Arkansas',
        abbreviation: 'AR',
        value: 'Arkansas',
    },
    {
        label: 'California',
        abbreviation: 'CA',
        value: 'California',
    },
    {
        label: 'Colorado',
        abbreviation: 'CO',
        value: 'Colorado',
    },
    {
        label: 'Connecticut',
        abbreviation: 'CT',
        value: 'Connecticut',
    },
    {
        label: 'Delaware',
        abbreviation: 'DE',
        value: 'Delaware',
    },
    {
        label: 'District of Columbia',
        abbreviation: 'DC',
        value: 'District of Columbia',
    },
    {
        label: 'Florida',
        abbreviation: 'FL',
        value: 'Florida',
    },
    {
        label: 'Georgia',
        abbreviation: 'GA',
        value: 'Georgia',
    },
    {
        label: 'Hawaii',
        abbreviation: 'HI',
        value: 'Hawaii',
    },
    {
        label: 'Idaho',
        abbreviation: 'ID',
        value: 'Idaho',
    },
    {
        label: 'Illinois',
        abbreviation: 'IL',
        value: 'Illinois',
    },
    {
        label: 'Indiana',
        abbreviation: 'IN',
        value: 'Indiana',
    },
    {
        label: 'Iowa',
        abbreviation: 'IA',
        value: 'Iowa',
    },
    {
        label: 'Kansas',
        abbreviation: 'KS',
        value: 'Kansas',
    },
    {
        label: 'Kentucky',
        abbreviation: 'KY',
        value: 'Kentucky',
    },
    {
        label: 'Louisiana',
        abbreviation: 'LA',
        value: 'Louisiana',
    },
    {
        label: 'Maine',
        abbreviation: 'ME',
        value: 'Maine',
    },
    {
        label: 'Maryland',
        abbreviation: 'MD',
        value: 'Maryland',
    },
    {
        label: 'Massachusetts',
        abbreviation: 'MA',
        value: 'Massachusetts',
    },
    {
        label: 'Michigan',
        abbreviation: 'MI',
        value: 'Michigan',
    },
    {
        label: 'Minnesota',
        abbreviation: 'MN',
        value: 'Minnesota',
    },
    {
        label: 'Mississippi',
        abbreviation: 'MS',
        value: 'Mississippi',
    },
    {
        label: 'Missouri',
        abbreviation: 'MO',
        value: 'Missouri',
    },
    {
        label: 'Montana',
        abbreviation: 'MT',
        value: 'Montana',
    },
    {
        label: 'Nebraska',
        abbreviation: 'NE',
        value: 'Nebraska',
    },
    {
        label: 'Nevada',
        abbreviation: 'NV',
        value: 'Nevada',
    },
    {
        label: 'New Hampshire',
        abbreviation: 'NH',
        value: 'New Hampshire',
    },
    {
        label: 'New Jersey',
        abbreviation: 'NJ',
        value: 'New Jersey',
    },
    {
        label: 'New Mexico',
        abbreviation: 'NM',
        value: 'New Mexico',
    },
    {
        label: 'New York',
        abbreviation: 'NY',
        value: 'New York',
    },
    {
        label: 'North Carolina',
        abbreviation: 'NC',
        value: 'North Carolina',
    },
    {
        label: 'North Dakota',
        abbreviation: 'ND',
        value: 'North Dakota',
    },
    {
        label: 'Ohio',
        abbreviation: 'OH',
        value: 'Ohio',
    },
    {
        label: 'Oklahoma',
        abbreviation: 'OK',
        value: 'Oklahoma',
    },
    {
        label: 'Oregon',
        abbreviation: 'OR',
        value: 'Oregon',
    },
    {
        label: 'Pennsylvania',
        abbreviation: 'PA',
        value: 'Pennsylvania',
    },
    {
        label: 'Rhode Island',
        abbreviation: 'RI',
        value: 'Rhode Island',
    },
    {
        label: 'South Carolina',
        abbreviation: 'SC',
        value: 'South Carolina',
    },
    {
        label: 'South Dakota',
        abbreviation: 'SD',
        value: 'South Dakota',
    },
    {
        label: 'Tennessee',
        abbreviation: 'TN',
        value: 'Tennessee',
    },
    {
        label: 'Texas',
        abbreviation: 'TX',
        value: 'Texas',
    },
    {
        label: 'Utah',
        abbreviation: 'UT',
        value: 'Utah',
    },
    {
        label: 'Vermont',
        abbreviation: 'VT',
        value: 'Vermont',
    },
    {
        label: 'Virginia',
        abbreviation: 'VA',
        value: 'Virginia',
    },
    {
        label: 'Washington',
        abbreviation: 'WA',
        value: 'Washington',
    },
    {
        label: 'West Virginia',
        abbreviation: 'WV',
        value: 'West Virginia',
    },
    {
        label: 'Wisconsin',
        abbreviation: 'WI',
        value: 'Wisconsin',
    },
    {
        label: 'Wyoming',
        abbreviation: 'WY',
        value: 'Wyoming',
    },
    {
        label: 'American Samoa',
        abbreviation: 'AS',
        value: 'American Samoa',
    },
    {
        label: 'Guam',
        abbreviation: 'GU',
        value: 'Guam',
    },
    {
        label: 'Northern Mariana Islands',
        abbreviation: 'MP',
        value: 'Northern Mariana Islands',
    },
    {
        label: 'Puerto Rico',
        abbreviation: 'PR',
        value: 'Puerto Rico',
    },
    {
        label: 'United States Virgin Islands',
        abbreviation: 'VI',
        value: 'United States Virgin Islands',
    },
];
