/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { PUBLISH_PREVIEW, RIDB_ACTION_PREFIX } from '../shared/constants/actions';
import { asyncDefaults, makeAsyncReducer, mergeReducers } from '../shared/utils/async';

const publishPreview = makeAsyncReducer(PUBLISH_PREVIEW);

export const getPublish = (state) => state.portal.publish?.data;

export default mergeReducers(asyncDefaults([]), RIDB_ACTION_PREFIX, publishPreview);
