/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FlexCol, FlexRow, Spacer, TextBlock } from 'sarsaparilla';
import { getCurrentUser } from '../reducers/currentUser';
import { ICurrentUser } from '../shared/types/auth/currentUser';
import { Globals } from '../shared/globals';
import RidbLink from '../shared/components/RidbLink';
import getAssetPath = Globals.getAssetPath;
import '../scss/containers/_StandardsPage.scss';

type StandardsPageProps = {
    currentUser: ICurrentUser;
};

function StandardsPage(props: Partial<StandardsPageProps> = {}) {
    const { currentUser } = props;
    const pdfPath = getAssetPath('pdf/Federal Camping Data Standard_2.1_12232024.pdf');
    return (
        <div className="standards-page-container">
            <Spacer size="sm" />
            <div>
                <FlexRow>
                    <FlexCol xs={12}>
                        <TextBlock className="page-title" width="xl" alignX="center">
                            Federal Camping Data Standard
                        </TextBlock>
                        <Spacer size="md" />
                        <TextBlock className="body-text" width="xl" alignX="center">
                            Federal land agencies that manage campgrounds established a
                            data standard to make it easier for campground information to
                            be accessed, exchanged, and used among agencies and by the
                            public.
                            <br />
                            The <b>Federal Camping Data Standard (FCDS):</b>
                            <ul>
                                <li>
                                    Identifies a common set of standardized terminology
                                    that can be consistently applied to a core set of
                                    campground information.
                                </li>
                                <li>
                                    Can be applied to existing databases and enable data
                                    to be combined or shared among agencies.
                                </li>
                                <li>
                                    Contributes to the Federal Government&apos;s ongoing
                                    pursuit of improving efficiency.
                                </li>
                            </ul>
                            Participating agencies include the National Park Service
                            (NPS), Bureau of Land Management (BLM), Bureau of Reclamation
                            (BOR), U.S. Fish and Wildlife Service (FWS), U.S. Forest
                            Service (USFS) and the U.S. Army Corps of Engineers (USACE).
                            <br />
                            Download the most recent version of the&nbsp;
                            <a
                                download="Federal Camping Data Standard 2.0.pdf"
                                href={pdfPath}
                            >
                                FCDS documentation.
                            </a>
                            {currentUser.apiKey.writeAccess && (
                                <>
                                    <br />
                                    In order to publish data to <b>Camping</b>&nbsp;
                                    <RidbLink to="/publish-to-camping">
                                        click here
                                    </RidbLink>
                                    .
                                </>
                            )}
                            <hr className="standards-hr-grey" />
                        </TextBlock>
                    </FlexCol>
                </FlexRow>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StandardsPage);
