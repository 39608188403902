/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { isNumber } from 'lodash';

const formatFullNameFromTokenDefault = { firstName: '', lastName: '' };
export const formatFullNameFromToken = ({
    firstName,
    lastName,
} = formatFullNameFromTokenDefault): string => `${firstName} ${lastName}`;

export const isValidEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const isValidPhoneNumber = (phone: string) => {
    const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return re.test(phone);
};

export const isValidLatitude = (lat: string) => {
    const re =
        /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    return re.test(lat);
};

export const isValidLongitude = (long: string) => {
    const re =
        /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    return re.test(long);
};

export const isValidURL = (url: string = '') => {
    const re =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    return re.test(url.toLowerCase());
};

export const isValidNumeric = (numeric: any) => {
    return typeof numeric === 'string' ? +numeric === 0 || !!+numeric : isNumber(numeric);
};

export const stripHTML = (text) =>
    text
        .replace(/<style[^>]*>.*<\/style>/gm, '')
        // Remove script tags and content
        .replace(/<script[^>]*>.*<\/script>/gm, '')
        // Remove all opening, closing and orphan HTML tags
        .replace(/<[^>]+>/gm, '')
        // Remove leading spaces and repeated CR/LF
        .replace(/([\r\n]+ +)+/gm, '');

export const toStringSafe = (value: any) =>
    value && value.toString ? value.toString() : value;
